import classNames from "classnames";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";

import style from "./navigation-panel.scss";
import { SidebarToggle } from "components/icons/SidebarToggle";
import { Logo } from "components/logo/Logo";
import NavigationMenu from "components/navigation/navigation-menu/NavigationMenu";
import { NavigationPanelFooter } from "components/navigation/navigation-panel/navigation-panel-footer/NavigationPanelFooter";
import { Breakpoint } from "domain/breakpoints";
import { StoreState } from "store";
import { getObject, RepositoryKey, setObject } from "utils/repository";

import testIds from "testIds.json";

interface Props {
    onNavigationPanelToggle: (value: boolean) => void;
}

const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    theme: state.themeReducer.theme,
}));

const NavigationPanel = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const navigationPanelRef = React.useRef<HTMLDivElement>(null);
    const navigationHeaderRef = React.useRef<HTMLDivElement>(null);
    const navigationContentRef = React.useRef<HTMLDivElement>(null);

    const [isMinimized, setIsMinimized] = React.useState(getObject(RepositoryKey.SIDE_NAVIGATION) === false);
    const [isResizing, setIsResizing] = React.useState(false);

    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
    });

    const toggleNavigation = () => {
        // Prevent hover events when the resizing is running.
        // Otherwise the resizing from maximized from minimized will be disturbed, and the layout doesn't look correct.
        setIsResizing(true);
        setIsMinimized((prevState) => !prevState);
    };

    // TODO BCC-3711: Check the correct responsive behavior for the new layout.
    React.useEffect(() => {
        window.onresize = () => {
            setWindowSize({
                width: window.innerWidth,
            });
        };
        if (getObject(RepositoryKey.SIDE_NAVIGATION) != null) {
            if (getObject(RepositoryKey.SIDE_NAVIGATION) == true) {
                setIsMinimized(false);
            }
            if (getObject(RepositoryKey.SIDE_NAVIGATION) == false) {
                setIsMinimized(true);
            }
        } else {
            if (windowSize.width <= Breakpoint.XL) {
                setIsMinimized(true);
            } else {
                if (windowSize.width > Breakpoint.XL) {
                    setIsMinimized(false);
                }
            }
        }
    }, [windowSize.width]);

    React.useEffect(() => {
        setObject(RepositoryKey.SIDE_NAVIGATION, !isMinimized);
        props.onNavigationPanelToggle(isMinimized);
    }, [isMinimized]);

    React.useEffect(() => {
        const panelElement = navigationPanelRef.current;
        const headerElement = navigationHeaderRef.current;
        const contentElement = navigationContentRef.current;

        if (!panelElement || !headerElement || !contentElement || isResizing) {
            return;
        }

        const handleAnimationEnd = () => {
            setIsResizing(false);
            // Notify any react-grid-layouts about the resized layout.
            window.dispatchEvent(new Event("resize"));
        };

        const handleMouseEnter = () => {
            panelElement.classList.add("minimizedPanelOpen");
        };

        const handleMouseExit = () => {
            panelElement.classList.remove("minimizedPanelOpen");
        };

        panelElement.addEventListener("transitionend", handleAnimationEnd);

        // Open the minimized navigation only when hovering either the navigation header or content.
        headerElement.addEventListener("mouseenter", handleMouseEnter);
        contentElement.addEventListener("mouseenter", handleMouseEnter);

        // Clear the hover class when the hover exists navigation panel.
        panelElement.addEventListener("mouseleave", handleMouseExit);

        return () => {
            panelElement.removeEventListener("transitionend", handleAnimationEnd);
            headerElement.removeEventListener("mouseenter", handleMouseEnter);
            contentElement.removeEventListener("mouseenter", handleMouseEnter);
            panelElement.removeEventListener("mouseleave", handleMouseExit);
        };
    }, []);

    return (
        <>
            <div
                className={classNames(style.navigationPanel, {
                    ["minimizedPanel"]: isMinimized,
                    [style.preventHoverActions]: isResizing,
                })}
                ref={navigationPanelRef}
            >
                <div className={style.navigationPanelHeader} ref={navigationHeaderRef}>
                    <div className={style.logoContainer}>
                        <Link to="/" test-id={testIds.navigation.togglePanel.defaultLink}>
                            <Logo className={style.maxLogo} color={props.theme.textColor} />
                            <Logo hiddenText className={style.minLogo} color={props.theme.textColor} />
                        </Link>
                    </div>
                    <button
                        className={style.toggleButton}
                        onClick={toggleNavigation}
                        data-testid={testIds.navigation.togglePanel.button}
                    >
                        <SidebarToggle color={props.theme.iconFillColor} rotate={isMinimized} />
                    </button>
                </div>

                <div className={style.navigationPanelContent} ref={navigationContentRef}>
                    <NavigationMenu />
                </div>

                <div className={style.navigationPanelFooter}>
                    <NavigationPanelFooter isMinimized={isMinimized} className={style.footerLayout} />
                </div>
            </div>
        </>
    );
};

const connectedSideNavigation = connector(NavigationPanel);
export { connectedSideNavigation as NavigationPanel };
