import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import CreateBootableUsbDrive from "./CreateBootableUsbDrive";
import PreConfigureStep from "./PreConfigureStep";
import ArrowButton from "components/home/ArrowButton";
import style from "components/home/bde-home-page/getting-started.scss";
import ChooseWorkflowTemplate from "components/home/choose-workflow-template/ChooseWorkflowTemplate";
import CircleNumber from "components/home/CircleWithNumber";
import StepCompletedIcon from "components/icons/StepCompletedIcon";
import StepTwoStatusBadgeIcon from "components/icons/StepTwoStatusBadge";
import { FEATURE_WORKFLOW_BDE } from "components/licenses/common";
import { LicenseData } from "domain/licenses";
import { checkIfMenuExpanded, isStepCompleted } from "domain/onboardings";
import { SoftwarePackagesData } from "domain/softwarePackages";
import { TemplateTableData } from "domain/workflowTemplates";
import { StoreState } from "store";

("");

interface Props {
    lastCompletedStepIndex: number;
    setLastCompletedStepIndex: (value: number) => void;
    fetchedSoftwarePackageData?: SoftwarePackagesData;
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    fetchedLicenses: LicenseData[];
    canViewReport: boolean;
    canViewWorkflows: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BdeSteps = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [closeInfoDialog, setCloseInfoDialog] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const preConfigureMenuExpanded = checkIfMenuExpanded(props.lastCompletedStepIndex, 1);
    const [hideSecondStep, setHideSecondStep] = React.useState(false);
    const [showWorkflowStep, setShowWorkflowStep] = React.useState(false);

    const [createBootableExpanded, setCreateBootableExpanded] = React.useState(false);

    React.useEffect(() => {
        if (preConfigureMenuExpanded) {
            setShowContent(true);
        }
    }, [preConfigureMenuExpanded]);

    React.useEffect(() => {
        if (success) {
            props.setLastCompletedStepIndex(1);
            setCreateBootableExpanded(true);
        }
    }, [success]);

    React.useEffect(() => {
        props.fetchedLicenses.map((each) => each.type == FEATURE_WORKFLOW_BDE && setShowWorkflowStep(true));
    }, []);

    const checkWorkflowLicense = () => {
        return props.canViewWorkflows && showWorkflowStep;
    };
    const handleClick = () => {
        if (!checkWorkflowLicense()) {
            setHideSecondStep(true);
            setShowContent((prevState) => !prevState);
        } else {
            setShowContent((prevState) => !prevState);
        }
    };

    return (
        <>
            {checkWorkflowLicense() && (
                <ChooseWorkflowTemplate
                    menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 0)}
                    stepCompleted={isStepCompleted(0, props.lastCompletedStepIndex)}
                    stepActive={true}
                    setLastCompletedStepIndex={props.setLastCompletedStepIndex}
                    defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                    requestFailureMessage={props.requestFailureMessage}
                    setHideSecondStep={setHideSecondStep}
                />
            )}
            {!showContent && closeInfoDialog && (
                <div className={success ? style.successColor : style.stepsToFollowLayout} onClick={handleClick}>
                    <div className={style.stepWidthAlignment}>
                        {success ? (
                            <StepCompletedIcon color={theme.defaultStarColor} checkMarkColor={theme.whiteColor} />
                        ) : !checkWorkflowLicense() ? (
                            <CircleNumber number={1} subStep={false} />
                        ) : (
                            <StepTwoStatusBadgeIcon />
                        )}
                    </div>
                    <div className={style.stepHeading}>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title")}
                    </div>
                    <ArrowButton success={success} showStep={showContent} />
                </div>
            )}
            {showContent && hideSecondStep && (
                <PreConfigureStep
                    setCloseInfoDialog={setCloseInfoDialog}
                    setShowContent={setShowContent}
                    setSuccess={setSuccess}
                    success={success}
                    fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                    showContent={showContent}
                />
            )}
            <CreateBootableUsbDrive
                menuExpanded={createBootableExpanded}
                stepCompleted={false}
                stepActive={true}
                setCloseInfoDialog={setCloseInfoDialog}
                setShowContent={setShowContent}
                setSuccess={setSuccess}
            />
        </>
    );
};

export default connector(BdeSteps);
