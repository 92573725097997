import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import style from "./mcs.scss";
import McsTable from "./McsTable";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { MCS_ROUTE } from "components/router/Routes";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttonsStyle from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export default function AllMcsView(): JSX.Element {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [registrationToken, setRegistrationToken] = useState<string>("");
    const [state, setState] = useState({ count: 0 });
    const ON_PREMISE_BMP_LICENSE_TYPE_ID = "51";

    // TODO BCC-4316 and BCC-4487 - Need to add required logic for unregister and edit as required.
    // I have just set random return for onMcsUnregister and onMcsEdit just to create the flow

    const fetchAvailableLicenses = async (licenseType: string) => {
        try {
            const data = await licenseService.fetchAvailableLicenses(licenseType);
            return data.availableLicenses;
        } catch (err) {
            logger.error("Exception occurred while fetching available licenses: ", err);
            throw err;
        }
    };

    const { data: availableLicenses, isLoading } = useQuery(["availableLicenses", ON_PREMISE_BMP_LICENSE_TYPE_ID], () =>
        fetchAvailableLicenses(ON_PREMISE_BMP_LICENSE_TYPE_ID)
    );

    function onMcsUnregister() {
        return setState((prevState) => ({ count: prevState.count }));
    }

    function onMcsEdit() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    const handleCopyToken = () => {
        hybridMcService
            .createMcRegistrationToken()
            .then((registrationToken) => {
                setRegistrationToken(registrationToken.token);
                return navigator.clipboard.writeText(registrationToken.token).then(() => {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.copyRegistrationCodeSuccess")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                });
            })
            .catch((error) => logger.error("Couldn't copy to clipboard", error));
    };

    const isCopyRegistrationCodeDisabled = (availableLicenses ?? 0) <= 0;

    const viewActions = (
        <>
            {isLoading ? (
                <LoadingIndicator small={true} />
            ) : (
                <div className={style.viewActionsContainer}>
                    <button
                        className={buttonsStyle.primaryButtonWithoutIcon}
                        onClick={() => {
                            handleCopyToken();
                            usageStatisticsService.sendEvent({
                                category: Category.MCS,
                                action: Action.REGISTER_MC,
                            });
                        }}
                        data-testid={testIds.workArea.mcs.getRegistrationCodeButton}
                        data-token={JSON.stringify({
                            token: registrationToken,
                        })}
                        disabled={isCopyRegistrationCodeDisabled}
                    >
                        {t("Mcs.copyRegistrationCode")}
                    </button>
                    <div data-testid={testIds.workArea.mcs.availableLicensesLabel}>
                        {t("Mcs.availableLicensesLabel", { availableLicenses: availableLicenses })}
                    </div>
                </div>
            )}
        </>
    );

    return (
        <ApplicationLayout
            viewTitle={t("Mcs.table.title")}
            view={
                <McsTable
                    count={state.count}
                    initialMcsData={{
                        tenantUuid: "",
                        name: "",
                        description: "",
                    }}
                    onMcsEdit={onMcsEdit}
                    onMcsUnregister={onMcsUnregister}
                />
            }
            viewActions={viewActions}
            mainRoute={MCS_ROUTE}
        />
    );
}
