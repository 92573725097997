import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import { GettingStarted as BDEGettingStarted } from "components/home/bde-home-page/GettingStarted";
import { GettingStarted as BMDEGettingStarted } from "components/home/bmde-home-page/GettingStarted";
import { DownloadIcon } from "components/home/home-icons/DownloadIcon";
import { ManualIcon } from "components/home/home-icons/ManualIcon";
import { WireIcon } from "components/home/home-icons/WireIcon";
import style from "components/home/product-card/product-card.scss";
import { subpageContext } from "components/layout/ApplicationLayout";
import { LicenseData } from "domain/licenses";
import { SoftwarePackagesData } from "domain/softwarePackages";
import { SoftwarePackageHomePage } from "domain/softwarePackages";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    fetchedLicenses: LicenseData[];
    fetchedSoftwarePackageData?: SoftwarePackagesData;
    softwarePackage: SoftwarePackageHomePage;
}

const ProductCard = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    return (
        <div className={style.productCard}>
            <div className={style.productCardWrapper}>
                <div className={style.item}>
                    <div className={style.name}>{props.softwarePackage.productName}</div>
                    <div className={style.version}>
                        {props.softwarePackage.onboarding ? (
                            <WireIcon
                                backgroundColor={theme.homeWireIconBackgroundColor}
                                color={theme.homeWireIconColor}
                                opacity={theme.homeWireIconOpacity}
                            />
                        ) : undefined}
                        <span>7.15</span>
                    </div>
                </div>
                {props.softwarePackage.onboarding !== undefined ? (
                    <div className={style.buttonWrapper}>
                        <ButtonContainer alignment="RIGHT" noSpacingTop={true}>
                            <Button
                                variant={"PRIMARY"}
                                type={"button"}
                                isSmallButton
                                onClick={() => {
                                    context?.setSubpage({
                                        content:
                                            props.softwarePackage.onboarding === "BDE" ? (
                                                <BDEGettingStarted
                                                    licenses={props.softwarePackage.versions}
                                                    fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                                                    fetchedLicenses={props.fetchedLicenses}
                                                />
                                            ) : (
                                                <BMDEGettingStarted
                                                    licenses={props.softwarePackage.versions}
                                                    fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                                                    fetchedLicenses={props.fetchedLicenses}
                                                />
                                            ),
                                        isModal: true,
                                    });
                                }}
                                testId={testIds.common.subpageView.cancelButton}
                            >
                                {t("Onboarding.home.getStartedButton")}
                            </Button>
                        </ButtonContainer>
                    </div>
                ) : (
                    <div className={style.manualDownloadsWrapper}>
                        <DownloadIcon color={theme.homeProductCardIconsColor} />
                        <ManualIcon color={theme.homeProductCardIconsColor} />
                    </div>
                )}
            </div>
            <div className={style.description}>
                <span>{props.softwarePackage.description}</span>
            </div>
        </div>
    );
};

export default ProductCard;
