import * as React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import WorkflowTemplateDetailsDialog from "./WorkflowTemplateDetailsDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { ModularIcon } from "components/icons/ModularIcon";
import { ReadyToUseIcon } from "components/icons/ReadyToUseIcon";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import Tooltip from "components/tooltip/Tooltip";
import publishTemplateStyle from "components/workflows/workflow-templates/published-workflow-template.scss";
import { TemplateTableData } from "domain/workflowTemplates";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { templateService, WorkflowTemplateSaveResponse } from "services/workflows/TemplateService";
import { ManifestWorkflowEditor, Profile, profileToNameMap, workflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";

interface Props<T extends TemplateTableData> {
    data: T[];
    loaded: boolean;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    workflowEditors: ManifestWorkflowEditor[];
}
const DOT = " • ";

export interface TemplateDetailsDialog {
    show: boolean;
    details?: TemplateTableData;
}

interface ConflictsResponseDto {
    conflicting_workflow_names: string[];
}

export interface ConflictsResponse {
    conflictingWorkflowNames: string[];
}

export interface ActiveWorkflowDialog {
    show: boolean;
    workflowUuid: string;
}

export function getTemplateTypeBadge(
    templateType: string,
    t: TFunction,
    readyToUseSkyBlueColor: string,
    modularPurpleColor: string
): JSX.Element {
    return templateType === "Ready to use" ? (
        <Tooltip content={t("workflowTemplate.workflowTemplateDetailsDialog.tooltip.modular")}>
            <div className={publishTemplateStyle.readyToUseContainer}>
                <ReadyToUseIcon linecolor={readyToUseSkyBlueColor} />
                <div className={publishTemplateStyle.textSpace}>{templateType.replace(/ /g, "-")}</div>
            </div>
        </Tooltip>
    ) : (
        <Tooltip content={t("workflowTemplate.workflowTemplateDetailsDialog.tooltip.readyToUse")}>
            <div className={publishTemplateStyle.modularContainer}>
                <ModularIcon color={modularPurpleColor} />
                <div className={publishTemplateStyle.textSpace}>{templateType}</div>
            </div>
        </Tooltip>
    );
}

function PublishedWorkflowTemplate<D extends TemplateTableData>(props: Props<D>): JSX.Element {
    if (!props.loaded) {
        return <LoadingIndicator />;
    }
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const { t } = useTranslation();
    const [templateDetailsDialog, setTemplateDetailsDialog] = React.useState<TemplateDetailsDialog>({
        show: false,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addedBadge, setAddedBadge] = React.useState<boolean>(false);
    const [showActiveWorkflowDialog, setShowActiveWorkflowDialog] = React.useState<ActiveWorkflowDialog>({
        show: false,
        workflowUuid: "",
    });
    const [conflictsException, setConflictsException] = React.useState<ConflictsResponse | undefined>(undefined);
    const [activeWorkflowLoading, setActiveWorkflowLoading] = React.useState<boolean>(false);

    const setSavedWorkflowActive = () => {
        setActiveWorkflowLoading(true);
        const abortController = new AbortController();
        workflowService.setDefault(showActiveWorkflowDialog.workflowUuid, abortController).then(() => {
            showSuccessNotificationToast(true);
            setShowActiveWorkflowDialog({
                show: false,
                workflowUuid: "",
            });
            setActiveWorkflowLoading(false);
        });
    };

    const showSuccessNotificationToast = (active: boolean) => {
        let message = t("workflowTemplate.workflowTemplateDetailsDialog.successToast.success", {
            templateName: templateDetailsDialog.details?.name,
        });
        if (active) {
            message += " " + t("workflowTemplate.workflowTemplateDetailsDialog.successToast.active");
        }
        return toast(
            <SuccessIcon
                successClass={layoutStyle.customToastSuccessIcon}
                color={theme.contentBackgroundColor}
                text={message}
            />,
            {
                closeButton: (closeToastProps) => (
                    <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                ),
                className: layoutStyle.customToastSuccessMessage,
            }
        );
    };

    const saveTemplate = () => {
        if (templateDetailsDialog.details) {
            setLoading(true);
            const abortController = new AbortController();

            templateService
                .save(templateDetailsDialog.details.uuid, abortController)
                .then((response: WorkflowTemplateSaveResponse) => {
                    setRequestFailureMessage("");
                    setLoading(false);
                    setAddedBadge(true);
                    !hasTenantCookie()
                        ? setShowActiveWorkflowDialog({
                              show: true,
                              workflowUuid: response.workflowUuid,
                          })
                        : showSuccessNotificationToast(false);
                })
                .catch((exception) => {
                    try {
                        if (!abortController.signal.aborted) {
                            setConflictsException(toConflictsResponse(JSON.parse(exception.message)));
                            setRequestFailureMessage(t("workflowTemplate.workflowTemplateDetailsDialog.error.message"));
                            setAddedBadge(false);
                            setShowActiveWorkflowDialog({
                                show: false,
                                workflowUuid: "",
                            });
                        }
                    } catch (error) {
                        setRequestFailureMessage(t("workflowTemplate.workflowTemplateDetailsDialog.error.failed"));
                        setConflictsException(undefined);
                        setAddedBadge(false);
                        setShowActiveWorkflowDialog({
                            show: false,
                            workflowUuid: "",
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div className={publishTemplateStyle.gridContainer}>
            {props.data.map((item, index) => (
                <div
                    key={index}
                    className={publishTemplateStyle.gridItem}
                    onClick={() => {
                        setTemplateDetailsDialog({ show: true, details: item });
                        setRequestFailureMessage("");
                    }}
                >
                    <div className={publishTemplateStyle.iconContainer}>
                        {getTemplateTypeBadge(
                            item.templateType,
                            t,
                            theme.readyToUseSkyBlueColor,
                            theme.modularPurpleColor
                        )}
                        {item.update ? (
                            <div className={publishTemplateStyle.added}>
                                <StatusBadge
                                    values={[
                                        {
                                            status: Status.WARNING,
                                            title: t("workflowTemplate.workflowTemplateDetailsDialog.update"),
                                        },
                                    ]}
                                    tooltip={true}
                                />
                            </div>
                        ) : (
                            item.saved && (
                                <div className={publishTemplateStyle.added}>
                                    <StatusBadge
                                        values={[
                                            {
                                                status: Status.SUCCESS,
                                                title: t("workflowTemplate.workflowTemplateDetailsDialog.added"),
                                            },
                                        ]}
                                        tooltip={true}
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <div className={publishTemplateStyle.dataContainer}>
                        <div className={publishTemplateStyle.deviceItem}>{item.name}</div>
                        <div className={publishTemplateStyle.versionContainer}>
                            <div className={publishTemplateStyle.textstyle}>
                                {profileToNameMap.get(item.profile as Profile)} {item.editorVersion}
                                {item.useCases.map((data: string) => (
                                    <>
                                        {DOT} {data}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <WorkflowTemplateDetailsDialog
                showTemplateDetailsDialog={templateDetailsDialog}
                setShowTemplateDetailsDialog={setTemplateDetailsDialog}
                setAddedBadge={setAddedBadge}
                setCount={props.setCount}
                requestFailureMessage={requestFailureMessage}
                loading={loading}
                conflictsException={conflictsException}
                addedBadge={addedBadge}
                saveTemplate={saveTemplate}
                showActiveWorkflowDialog={showActiveWorkflowDialog}
                setShowActiveWorkflowDialog={setShowActiveWorkflowDialog}
                setSavedWorkflowActive={setSavedWorkflowActive}
                showSuccessNotificationToast={showSuccessNotificationToast}
                activeWorkflowLoading={activeWorkflowLoading}
                workflowEditors={props.workflowEditors}
                previewOnly={false}
            />
        </div>
    );
}

export const toConflictsResponse = (dto: ConflictsResponseDto): ConflictsResponse => {
    return {
        conflictingWorkflowNames: dto.conflicting_workflow_names,
    };
};
export default PublishedWorkflowTemplate;
