import { TableData } from "./table";

export interface Mcs {
    tenantUuid: string;
    name?: string;
    registrationDate?: string;
    lastUpdatedDate?: string;
    status?: McState;
    uuid?: string;
    description?: string;
    deadlineDate?: string;
}

export interface McTable {
    cursor: string;
    mcdata: McsTableData[];
}

export const MC_STATES = ["ACTIVE", "UNREGISTERED", "SYNC_FAILED", "HALTED"] as const;
export type McState = typeof MC_STATES[number];

export interface License {
    remaining: number;
}

export interface McsTableData extends TableData {
    name: string;
    description: string;
    status: McState;
    uuid: string;
    registrationDate: string;
    lastUpdatedDate: string;
    deadlineDate: string;
    reportCount: number;
    userCount: number;
    workflowCount: number;
    licenses: Record<string, License>;
    halted: boolean;
}

export interface McsDataDto {
    name: string;
    registration_date: string;
    last_updated_date: string;
    status: McState;
    uuid: string;
    description: string;
    deadline_date: string;
    halted: boolean;
    report_count: number;
    user_count: number;
    workflow_count: number;
    licenses: Record<string, License>;
}

export interface McsResponseDto {
    mcs: McsDataDto[];
    cursor: string;
}

export function toMcsData(dto: McsDataDto): McsTableData {
    return {
        name: dto.name,
        description: dto.description,
        status: dto.status,
        uuid: dto.uuid,
        registrationDate: dto.registration_date,
        reportCount: dto.report_count,
        userCount: dto.user_count,
        workflowCount: dto.workflow_count,
        licenses: dto.licenses,
        lastUpdatedDate: dto.last_updated_date,
        deadlineDate: dto.deadline_date,
        halted: dto.halted,
    };
}
