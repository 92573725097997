import * as React from "react";

export const OnPremBmp = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            d="M19.4876 3.57617H4.51165C3.47777 3.57617 2.63965 4.41429 2.63965 5.44817V14.8082C2.63965 15.842 3.47777 16.6802 4.51165 16.6802H19.4876C20.5215 16.6802 21.3596 15.842 21.3596 14.8082V5.44817C21.3596 4.41429 20.5215 3.57617 19.4876 3.57617Z"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.25586 20.4238H15.7439"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 16.6797V20.4237"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
