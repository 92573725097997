import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";

import style from "components/home/bde-home-page/getting-started.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { ALL_REPORTS_ROUTE } from "components/router/Routes";
import { OnboardingProductNames, OnboardingProductType } from "domain/onboardings";
import { reportService } from "services/report/erasure/ReportService";
import { StoreState } from "store";

interface Props {
    product: OnboardingProductType;
    stepCompleted: boolean;
    //  setStepCompleted: (value: boolean) => void;
}
const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ViewReportSubStep = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [reportsCount, setReportsCount] = React.useState(0);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState("");
    const history = useHistory();
    const navigateToReports = () => history.push(ALL_REPORTS_ROUTE.path);
    const abortController = new AbortController();
    abortControllers.push(abortController);
    const intervalRef = React.useRef<NodeJS.Timeout>();
    const attemptCountRef = React.useRef(0);
    const MAX_ATTEMPTS = 10;
    const POLLING_INTERVAL = 5000;

    const paths = ["report.uuid", "report.product_name", "report.report_date"];

    const fetchReports = () => {
        setLoading(true);
        reportService
            .fetchAllReports({
                abortController,
                sortAscending: false,
                paths: paths,
                search: OnboardingProductNames[props.product],
            })
            .then((response) => {
                if (response.reports.length > 0) {
                    setReportsCount(response.reports.length);
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    setLoading(false);
                } else {
                    setReportsCount(0);
                    attemptCountRef.current += 1;

                    if (attemptCountRef.current >= MAX_ATTEMPTS) {
                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                            setLoading(false);
                        }
                    }
                }
            })
            .catch((exception) => {
                setRequestFailureMessage(exception);
            });
    };
    React.useEffect(() => {
        fetchReports();

        intervalRef.current = setInterval(() => {
            if (attemptCountRef.current < MAX_ATTEMPTS) {
                fetchReports();
            }
        }, POLLING_INTERVAL);

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return (
        <>
            <div className={style.inProgressInfoBox}>
                {loading ? (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.description")} />
                ) : reportsCount === 1 ? (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.reportReady")} />
                ) : (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.description")} />
                )}
                {loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <div>
                        {reportsCount > 1 ? (
                            <button
                                className={style.previewTemplateButton}
                                onClick={() => {
                                    navigateToReports();
                                }}
                            >
                                {t("Onboarding.bmde.gettingStarted.steps.viewReport.goToReports")}
                            </button>
                        ) : reportsCount === 1 ? (
                            <button
                                className={style.previewTemplateButton}
                                onClick={() => {
                                    //view single report
                                    navigateToReports();
                                }}
                            >
                                {t("Onboarding.bmde.gettingStarted.steps.viewReport.viewReport")}
                            </button>
                        ) : null}
                    </div>
                )}
                {requestFailureMessage !== "" ? (
                    <div>{t("Onboarding.bmde.gettingStarted.steps.viewReport.reportFetchTimeOut")}</div>
                ) : null}
            </div>
        </>
    );
};

interface LoadingReportsProps {
    description: string;
}
const LoadingReports = (props: LoadingReportsProps): JSX.Element => {
    return (
        <>
            <div className={style.inProgressDescription}>{props.description}</div>
        </>
    );
};

export default connector(ViewReportSubStep);
