import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AdditionCard from "components/home/addition-card/AdditionCard";
import CurrentPlan from "components/home/current-plan/CurrentPlan";
import { InfoBadgeIcon } from "components/home/home-icons/InfoBadgeIcon";
import style from "components/home/home.scss";
import ProductCard from "components/home/product-card/ProductCard";
import Support from "components/home/support/Support";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import { AUTH_LICENSE_VIEW_OWN } from "domain/authority";
import { LicenseData } from "domain/licenses";
import { getHomepageProductsMappingList } from "domain/onboardings";
import { SoftwarePackageHomePage, SoftwarePackagesData } from "domain/softwarePackages";
import { licenseService } from "services/licenses/LicenseService";
import { softwarePackagesService } from "services/software-packages/SoftwarePackagesService";
import { getCurrentTenantDetails } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { TenantDetails } from "store/tenantDetails";

const Home = (): JSX.Element => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [infoProductsState, setInfoProductsState] = useState<SoftwarePackageHomePage[]>([]);
    const [assetProductsState, setAssetProductsState] = useState<SoftwarePackageHomePage[]>([]);
    const [additionalProductsState, setAdditionalProductsState] = useState<SoftwarePackageHomePage[]>([]);

    const [loading, setLoading] = React.useState(false);
    const [tenantData, setTenantData] = React.useState<TenantDetails>();
    const [fetchedSoftwarePackageData, setFetchedSoftwarePackageData] = React.useState<SoftwarePackagesData>();
    const productsMappingList = getHomepageProductsMappingList();
    const [licenseList, setLicenseList] = React.useState<LicenseData[]>([]);
    const { t } = useTranslation();
    const licensesAbortController = new AbortController();
    abortControllers.push(licensesAbortController);

    const fetchProductsData = () => {
        const licensePromise = licenseService.fetchAllLicenses({
            abortController: licensesAbortController,
            tenantIdentifier: tenantData?.uuid,
            own: true,
        });

        if (userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW_OWN])) {
            try {
                setLoading(true);
                const softwarePackagesPromise = softwarePackagesService.fetchSoftwarePackages();
                Promise.all([softwarePackagesPromise, licensePromise])
                    .then(([softwarePackagesData, licenseData]) => {
                        setFetchedSoftwarePackageData(softwarePackagesData);
                        setLicenseList(licenseData.licenses.licenseData);
                        const mainProducts: SoftwarePackageHomePage[] = [];
                        const addedProductIds = new Set();
                        licenseData.licenses.licenseData.map((license) => {
                            softwarePackagesData.productDownloads.map((product) => {
                                if (
                                    product.licenseIds.includes(license.type) &&
                                    !addedProductIds.has(product.productName)
                                ) {
                                    mainProducts.push(product);
                                    addedProductIds.add(product.productName);
                                }
                            });
                        });

                        const infoProducts: Set<SoftwarePackageHomePage> = new Set();
                        const assetProducts: Set<SoftwarePackageHomePage> = new Set();

                        mainProducts.map((pack) => {
                            productsMappingList.map((mapItem) => {
                                if (mapItem.licensesIds.some((item) => pack.licenseIds.includes(item))) {
                                    pack.description = mapItem.description;
                                    pack.onboarding = mapItem.onboarding;
                                    if (mapItem.group == "information") {
                                        infoProducts.add(pack);
                                    } else if (mapItem.group == "asset") {
                                        assetProducts.add(pack);
                                    }
                                }
                            });
                        });
                        const additionalProducts = mainProducts.filter(
                            (item) => ![...infoProducts, ...assetProducts].includes(item)
                        );

                        setInfoProductsState([...infoProducts]);
                        setAssetProductsState([...assetProducts]);
                        setAdditionalProductsState([...additionalProducts]);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } catch (e) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setTenantData(getCurrentTenantDetails());
        fetchProductsData();
    }, []);

    const assetProductCards =
        assetProductsState.length > 0 ? (
            <div>
                <div className={style.headerWrapper}>
                    <h3>{t("Onboarding.home.assetProductsHeader")}</h3>
                    <Tooltip content={t("Onboarding.home.assetHeaderLabel")} placement={"right-end"}>
                        <InfoBadgeIcon color={theme.homeInfoBadgeColor} />
                    </Tooltip>
                </div>
                <div className={style.productCardsWrapper}>
                    {assetProductsState.map((softwarePackage, index) => (
                        <ProductCard
                            key={index}
                            softwarePackage={softwarePackage}
                            fetchedSoftwarePackageData={fetchedSoftwarePackageData}
                            fetchedLicenses={licenseList}
                        />
                    ))}
                </div>
            </div>
        ) : null;

    const informationProductCards =
        infoProductsState.length > 0 ? (
            <>
                <div className={style.headerWrapper}>
                    <h3>{t("Onboarding.home.informationProductsHeader")}</h3>
                    <Tooltip content={t("Onboarding.home.infoHeaderLabel")} placement={"right-end"}>
                        <InfoBadgeIcon color={theme.homeInfoBadgeColor} />
                    </Tooltip>
                </div>
                <div className={style.productCardsWrapper}>
                    {infoProductsState.map((softwarePackage, index) => (
                        <ProductCard key={index} softwarePackage={softwarePackage} fetchedLicenses={licenseList} />
                    ))}
                </div>
            </>
        ) : null;

    const additionsProductCards =
        additionalProductsState.length > 0 ? (
            <>
                <h3>{t("Onboarding.home.additionalToolsHeader")}</h3>
                <div className={style.additionalToolsWrapper}>
                    {additionalProductsState.map((softwarePackage, index) => (
                        <AdditionCard key={index} softwarePackage={softwarePackage} />
                    ))}
                </div>
            </>
        ) : null;

    return (
        <>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <div>
                    <div className={style.planAndSupport}>
                        <CurrentPlan tenantData={tenantData} />
                        <Support />
                    </div>
                    {infoProductsState.length > 0 &&
                    assetProductsState.length > 0 &&
                    additionalProductsState.length > 0 ? (
                        <div className={style.products}>
                            <div className={style.assets}>{assetProductCards}</div>
                            <div className={style.information}>{informationProductCards}</div>
                            <hr />
                            <section className={style.additionalTools}>{additionsProductCards}</section>
                        </div>
                    ) : (
                        // TODO BCC-4330 Change it to well looking message and add to tranlate.json
                        "You haven't products to use"
                    )}
                </div>
            )}
        </>
    );
};

export default Home;
