import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import ArrowButton from "components/home/ArrowButton";
import style from "components/home/bde-home-page/getting-started.scss";
import ProductCard from "components/home/bde-home-page/ProductCard";
import SubStep from "components/home/bde-home-page/SubStep";
import { displayStepCompletionIcon } from "components/home/bmde-home-page/GettingStarted";
import StepCompletedText from "components/home/StepCompletedText";
import { BdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { SoftwarePackagesData } from "domain/softwarePackages";
import { Onboarding, onboardingsService } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

interface Props {
    setCloseInfoDialog: (value: boolean) => void;
    setShowContent: (value: boolean) => void;
    setSuccess: (value: boolean) => void;
    success: boolean;
    fetchedSoftwarePackageData?: SoftwarePackagesData;
    showContent: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const TRANSLATION_KEYS = {
    TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title",
    STEPS: {
        ONE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepOne",
        TWO: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepTwo",
        THREE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepThree",
        FOUR: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepFour",
        FIVE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepFive",
    },
    PRODUCTS: {
        CONFIG_TOOL: {
            TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationTool",
            DESCRIPTION: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationToolDescription",
        },
        ERASURE_FILE: {
            TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.erasureFile",
            DESCRIPTION: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.erasureFileDescription",
        },
    },
};

const StepsToFollow = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [handleDropdown, setHandleDropDown] = React.useState<boolean>(false);
    const handleConfigToolDownload = () => {
        return setHandleDropDown(true);
    };

    const handleErasureFileDownload = () => {
        // Implementation for erasure file download
    };
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const onSubmitEventHandler = async (productSteps: Onboarding): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        onboardingsService.saveOnboardingStep({
            step: productSteps.step,
            product: productSteps.product,
        });
    };

    const clickEvent = () => {
        props.setShowContent(!props.showContent);
    };

    return (
        <>
            <div className={style.infoBox}>
                <div className={style.subStepsToFollowLayout} onClick={clickEvent}>
                    <div className={style.stepWidthAlignment}>
                        {displayStepCompletionIcon(
                            props.showContent,
                            props.success,
                            2,
                            true,
                            props.theme.defaultStarColor,
                            props.theme.whiteColor,
                            props.theme.secondaryWarningBackgroundColor,
                            props.theme.descriptionTextColor
                        )}
                    </div>
                    <div className={style.stepHeading}>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title")}
                    </div>
                    <ArrowButton success={props.showContent} showStep={props.showContent} />
                </div>
                <div className={classNames(style.divider, style.dividerLayout)} />
                <SubStep iconNumber={1} translationKey={TRANSLATION_KEYS.STEPS.ONE} subStep={true} />
                <div className={style.portalContent}>
                    <ProductCard
                        titleKey={TRANSLATION_KEYS.PRODUCTS.CONFIG_TOOL.TITLE}
                        descriptionKey={TRANSLATION_KEYS.PRODUCTS.CONFIG_TOOL.DESCRIPTION}
                        onDownload={handleConfigToolDownload}
                        handleDropdown={handleDropdown}
                        fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                    />

                    <ProductCard
                        titleKey={TRANSLATION_KEYS.PRODUCTS.ERASURE_FILE.TITLE}
                        descriptionKey={TRANSLATION_KEYS.PRODUCTS.ERASURE_FILE.DESCRIPTION}
                        onDownload={handleErasureFileDownload}
                    />
                </div>

                <SubStep iconNumber={2} translationKey={TRANSLATION_KEYS.STEPS.TWO} subStep={true} />

                <SubStep iconNumber={3} translationKey={TRANSLATION_KEYS.STEPS.THREE} subStep={true} />

                <div className={style.portalContent}>
                    <div className={style.hostName}>
                        <strong>{t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.hostname")}</strong>
                        {props.user?.erasureClientEndpoint}
                    </div>
                    <div className={style.url}>
                        <strong>{t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.port")}</strong>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.443")}
                    </div>
                </div>

                <SubStep iconNumber={4} translationKey={TRANSLATION_KEYS.STEPS.FOUR} subStep={true} infoIcon={true} />

                <SubStep iconNumber={5} translationKey={TRANSLATION_KEYS.STEPS.FIVE} subStep={true} />
                <div className={style.markComplete}>
                    {props.success ? (
                        <div className={style.templateSelectedBlock}>
                            <StepCompletedText
                                successText={t(
                                    "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.completed"
                                )}
                            />
                        </div>
                    ) : (
                        <button
                            className={classNames(buttons.primaryButtonWithoutIcon)}
                            onClick={() => {
                                onSubmitEventHandler({
                                    step: BdeOnboardingSteps.STEP_PRE_CONFIGURATION,
                                    product: OnboardingProductType.BDE,
                                }),
                                    props.setCloseInfoDialog(true),
                                    props.setShowContent(false),
                                    props.setSuccess(true);
                            }}
                        >
                            {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.markComplete")}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default connector(StepsToFollow);
