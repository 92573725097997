import classNames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import style from "./button.scss";
import Export from "components/icons/Export";
import Print from "components/icons/Print";
import SimpleDeleteIcon from "components/icons/SimpleDeleteIcon";
import { StoreState } from "store";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant: Variant;
    icon?: Icon;
    customIcon?: JSX.Element;
    testId?: string;
    tooltipText?: string;
    disabled?: boolean;
    isSmallButton?: boolean;
}

// Variants follow the BMP Design system in Figma. Don't add your own if it's not in the design system!
type Variant = "PRIMARY" | "SECONDARY" | "TERTIARY";
type Icon = "EXPORT" | "DELETE" | "PRINT";

const Button = React.forwardRef<HTMLButtonElement, Props>(function Button(props, ref) {
    const { variant, icon, customIcon, testId, tooltipText, isSmallButton, ...buttonProps } = props;
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const buttonHasIcon = customIcon || icon;

    const buttonIcon = () => {
        if (!buttonHasIcon) {
            return;
        }

        if (customIcon) {
            return customIcon;
        }

        switch (icon) {
            case "EXPORT":
                return <Export color={theme.linkTextColor} />;
            case "DELETE":
                return <SimpleDeleteIcon color={theme.errorBackgroundColor} />;
            case "PRINT":
                return <Print color={theme.linkTextColor} />;
            default:
                return;
        }
    };

    const buttonClasses = classNames(style.button, style.buttonWithoutIcon, {
        [style.primaryButton]: variant === "PRIMARY",
        [style.secondaryButton]: variant === "SECONDARY",
        [style.tertiaryButton]: variant === "TERTIARY",
        [style.icon]: buttonHasIcon,
        [style.deleteButton]: icon === "DELETE",
        [style.smallButton]: isSmallButton,
    });

    const tooltipProperties = {
        "data-tip": tooltipText,
        "data-effect": "solid",
    };

    React.useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <>
            <button
                {...buttonProps}
                ref={ref}
                className={buttonClasses}
                data-testid={testId}
                {...(tooltipText && tooltipProperties)}
                disabled={props.disabled}
            >
                {buttonIcon()}
                <span>{buttonProps.children}</span>
            </button>
        </>
    );
});

export default Button;
