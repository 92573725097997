import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import KebabMenu from "../kebab-menu/KebabMenu";
import style from "./mcs.scss";
import McsKebabMenu from "./McsKebabMenu";
import McSyncInformationView from "./McSyncInformationView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import { Mcs, McsTableData, McState } from "domain/mc";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttons from "styles/buttons.scss";
import layoutStyle from "styles/layout.scss";
import statusStyle from "styles/status.scss";
import { RepositoryKey } from "utils/repository";

import infoBadge from "assets/images/icons/product-icons/infoBadge.svg";

import testIds from "testIds.json";

interface TableState {
    Mcs: McsTableData[];
    cursor: string;
    scrollPosition?: number;
}

export interface Props {
    count: number;
    initialMcsData: Mcs;
    search?: string;
    onMcsEdit: () => void;
    onMcsUnregister: () => void;
}

const STATUS_MAPPING: Record<McState, Status> = {
    ACTIVE: Status.SUCCESS,
    UNREGISTERED: Status.NEUTRAL,
    SYNC_FAILED: Status.WARNING,
    HALTED: Status.ERROR,
};

function deriveRemainingDays(lastUpdated: string, deadlineDate: string) {
    const lastUpdatedDate = new Date(lastUpdated).getTime();
    const deadline = new Date(deadlineDate).getTime();

    // Convert milliseconds to days
    const remainingDays = Math.ceil((deadline - lastUpdatedDate) / (1000 * 60 * 60 * 24));

    return remainingDays;
}

const McsTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        Mcs: [],
        cursor: "",
        scrollPosition: 0,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string | JSX.Element>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [mcSyncInformationVisibility, setMcSyncInformationVisibility] = React.useState(false);
    const [selectedMc, setSelectedMc] = React.useState<McsTableData>();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const errorText = (
        <div>
            <div className={style.errorText}>
                <span>{t("Mcs.table.requestFailed")}</span>
                <span>{t("Mcs.table.requestFailed1")}</span>
                <span>{t("Mcs.table.requestFailed2")}</span>
            </div>
        </div>
    );

    const hideSyncInformation = () => {
        setMcSyncInformationVisibility(false);
    };

    const viewSyncInformation = (
        <Modal
            isOpen={mcSyncInformationVisibility}
            hideModal={hideSyncInformation}
            modalTitle={t("Mcs.onPremBmpInfoModal.title")}
        >
            {selectedMc != null && <McSyncInformationView mcsTableData={selectedMc} />}
        </Modal>
    );

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        hybridMcService
            .fetchData(abortController)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.Mcs.length - 1,
                    Mcs: prevState.Mcs.concat(data.mcdata),
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController?.signal.aborted) {
                    setRequestFailureMessage(errorText);
                }
            })
            .finally(() => {
                if (!abortController?.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const deriveStatusConfig = (value: McState) => {
        const status: Status = STATUS_MAPPING[value];
        return { status: status, title: value };
    };

    const columns: Array<Column<McsTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.name")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => {
                return (
                    <div className={classNames(style.alignment)}>
                        <div className={classNames(style.marginRight)}>
                            {
                                <KebabMenu>
                                    <McsKebabMenu
                                    // TODO  BCC-4316 and BCC-4487 - enable in case of edit and unregister

                                    // name={cellInfo.cell.row.original.name}
                                    // registrationDate={cellInfo.cell.row.original.registrationDate}
                                    // lastSynced={cellInfo.cell.row.original.lastSynced}
                                    // status={cellInfo.cell.row.original.status}
                                    // uuid={cellInfo.cell.row.original.uuid}
                                    // description={cellInfo.cell.row.original.description}
                                    // onMcsEdit={props.onMcsEdit}
                                    // onMcsUnregister={props.onMcsUnregister}
                                    />
                                </KebabMenu>
                            }
                        </div>
                        <a
                            onClick={() => {
                                setMcSyncInformationVisibility(true);
                                setSelectedMc(cellInfo.cell.row.original);
                            }}
                        >
                            <TextWithTooltip text={cellInfo.value} />
                        </a>
                    </div>
                );
            },
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.registrationDate")} key="3" />,
            accessor: "registrationDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.lastSynced")} key="4" />,
            accessor: "lastUpdatedDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: () => (
                <div className={classNames(style.statusTitleInfo)} key="5">
                    <TextWithTooltip text={t("Mcs.table.status")} />
                    <TextWithTooltip text={t("Mcs.table.statusInfo")}>
                        <img src={infoBadge} />
                    </TextWithTooltip>
                </div>
            ),
            accessor: "status",
            Cell: (cellInfo) => {
                const status: McState = cellInfo.cell.row.original.status;

                if (status === "SYNC_FAILED") {
                    const daysRemaining = deriveRemainingDays(
                        cellInfo.cell.row.original.lastUpdatedDate,
                        cellInfo.cell.row.original.deadlineDate
                    );
                    return (
                        <div>
                            <span className={classNames(style.statusBadge, statusStyle.statusWarning)}>
                                {daysRemaining} {t("Mcs.table.syncText")}
                            </span>
                        </div>
                    );
                }

                return <StatusBadge values={[deriveStatusConfig(status)]} tooltip={true} />;
            },
            width: deriveColumnWidth(15, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.uuid")} key="6" />,
            accessor: "uuid",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.deadline")} key="7" />,
            accessor: "deadlineDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
    ];

    React.useEffect(() => {
        setTableState({ Mcs: [], cursor: "", scrollPosition: 0 });
        fetchData(true);
    }, [props.count]);

    let dataCount = null;
    if (tableState.Mcs.length > 0) {
        dataCount = <div>{t("Common.defaultSearchResultHint", { dataCount: tableState.Mcs.length })}</div>;
    }
    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.MCS_TABLE}
                    data={tableState.Mcs}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    emptyMessage={""}
                    testId={testIds.workArea.mcs.table.itself}
                />
            </div>
            {tableState.cursor != null &&
                tableState.Mcs.length >= TABLE_PAGE_LIMIT &&
                tableState.Mcs.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={buttons.loadMoreButtonWithoutIcon}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                action: Action.LOAD_MORE,
                                category: Category.MCS,
                            });
                        }}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            {viewSyncInformation}
        </>
    );
};

export default McsTable;
