import * as React from "react";
import { useSelector } from "react-redux";

import style from "components/home/addition-card/addition-card.scss";
import { DownloadIcon } from "components/home/home-icons/DownloadIcon";
import { ManualIcon } from "components/home/home-icons/ManualIcon";
import { SoftwarePackageHomePage } from "domain/softwarePackages";
import { StoreState } from "store";

interface Props {
    softwarePackage: SoftwarePackageHomePage;
}

const AdditionCard = (props: Props): JSX.Element => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    return (
        <div className={style.additionCard}>
            <div className={style.topRow}>
                <div className={style.name}>{props.softwarePackage.productName}</div>
                <div className={style.version}>
                    <span>5.75.15</span>
                </div>
            </div>
            <div className={style.bottomRow}>
                <DownloadIcon color={theme.homeProductCardIconsColor} />
                <ManualIcon color={theme.homeProductCardIconsColor} />
            </div>
        </div>
    );
};

export default AdditionCard;
