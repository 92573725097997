import classNames from "classnames";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import ErrorMessage from "components/error-message/ErrorMessage";
import BdeSteps from "components/home/bde-home-page/BdeSteps";
import style from "components/home/bde-home-page/getting-started.scss";
import BmdeSteps from "components/home/bmde-home-page/BmdeSteps";
import ProgressBar from "components/home/ProgressBar";
import IconButton from "components/icon-button/IconButton";
import LeftArrow from "components/icons/LeftArrow";
import { subpageContext } from "components/layout/ApplicationLayout";
import { FEATURE_REPORTING } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Heading from "components/typography/heading/Heading";
import {
    AUTH_REPORT_VIEW,
    AUTH_WORKFLOW_DEFAULT,
    AUTH_WORKFLOW_TEMPLATE_SAVE,
    AUTH_WORKFLOW_VIEW,
} from "domain/authority";
import { LicenseData } from "domain/licenses";
import {
    BdeOnboardingSteps,
    BmdeOnboardingSteps,
    displayProgressBarProgress,
    getInitialProgressPercentage,
    getLastCompletedStepIndexForBde,
    getLastCompletedStepIndexForBmde,
    mapCompletedStepToBdeOnboardingStep,
    mapCompletedStepToBmdeOnboardingStep,
    OnboardingProductType,
} from "domain/onboardings";
import { SoftwarePackagesData, Versions } from "domain/softwarePackages";
import { ConnectedWorkflows } from "domain/workflows";
import { TemplateTableData } from "domain/workflowTemplates";
import { onboardingsService } from "services/onboardings/OnboardingsService";
import { userSessionService } from "services/user/UserSessionService";
import { templateService } from "services/workflows/TemplateService";
import { EditorGeneration, Profile } from "services/workflows/WorkflowService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    product: string; // TODO BCC-4404 Change this to enum type
    visible: boolean;
    title: string;
    extraHeaderText?: string;
    description: string;
    footerVisibility?: boolean;
    buttons?: React.ReactNode;
    children?: React.ReactNode;
    primaryButtonRequired?: boolean;
    handleRefresh?: (value: boolean) => void;
    fetchedSoftwarePackageData?: SoftwarePackagesData;
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
}

export const GettingStartedLayout = (props: Props) => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [lastCompletedStep, setLastCompletedStep] = React.useState<string>("");
    const bmdeStep = React.useCallback((): BmdeOnboardingSteps | undefined => {
        if (props.product === OnboardingProductType.BMDE) {
            return mapCompletedStepToBmdeOnboardingStep(lastCompletedStep);
        }
    }, [lastCompletedStep]);
    const bdeStep = React.useCallback((): BdeOnboardingSteps | undefined => {
        if (props.product === OnboardingProductType.BDE) {
            return mapCompletedStepToBdeOnboardingStep(lastCompletedStep);
        }
    }, [lastCompletedStep]);
    const lastCompletedBmdeStep = !bmdeStep() ? -1 : getLastCompletedStepIndexForBmde(bmdeStep());
    const lastCompletedBdeStep = !bdeStep ? -1 : getLastCompletedStepIndexForBde(bdeStep());
    const [lastCompletedStepIndexBmde, setLastCompletedStepIndexBmde] = React.useState<number>(-1);
    const [lastCompletedStepIndexBde, setLastCompletedStepIndexBde] = React.useState<number>(-1);
    React.useEffect(() => {
        setLastCompletedStepIndexBmde(lastCompletedBmdeStep);
    }, [lastCompletedBmdeStep]);
    React.useEffect(() => {
        setLastCompletedStepIndexBde(lastCompletedBdeStep);
    }, [lastCompletedBdeStep]);
    const [progressValueBmde, setProgressValueBmde] = React.useState<number>(
        getInitialProgressPercentage(lastCompletedStepIndexBmde)
    );
    React.useEffect(() => {
        if (lastCompletedBmdeStep === lastCompletedStepIndexBmde) {
            setProgressValueBmde(getInitialProgressPercentage(lastCompletedStepIndexBmde));
        } else {
            setProgressValueBmde(displayProgressBarProgress(lastCompletedStepIndexBmde, progressValueBmde));
        }
    }, [lastCompletedStepIndexBmde]);
    const [progressValueBde, setProgressValueBde] = React.useState<number>(
        getInitialProgressPercentage(lastCompletedStepIndexBde)
    );
    React.useEffect(() => {
        if (lastCompletedBdeStep === lastCompletedStepIndexBde) {
            setProgressValueBde(getInitialProgressPercentage(lastCompletedStepIndexBde));
        } else {
            setProgressValueBde(displayProgressBarProgress(lastCompletedStepIndexBde, progressValueBde));
        }
    }, [lastCompletedStepIndexBde]);
    const connectedWorkflows: ConnectedWorkflows = {
        collectWorkflows: [],
        gradingPanelWorkflows: [],
        remoteWorkflows: 0,
        runDiagnoseWorkflows: [],
        runProcessFlowWorkflows: [],
        subWorkflows: [],
        totalConnectedWorkflows: 0,
        parentWorkflows: [],
    };

    const editorGeneration: EditorGeneration = "V1";
    const profile: Profile = Profile.ALL;
    const defaultTemplateTable: TemplateTableData = {
        connectedWorkflows: connectedWorkflows,
        coreActivities: [],
        created: "",
        creator: "",
        editorGeneration: editorGeneration,
        editorVersion: "",
        modified: "",
        onboarding: false,
        own: false,
        processors: [],
        profile: profile,
        published: false,
        saved: false,
        templateType: "",
        update: false,
        usageCount: 0,
        useCases: [],
        userName: "",
        userUuid: "",
        description: "",
        name: "",
        uuid: "",
    };
    const abortController = new AbortController();
    const [isLoading, setIsLoading] = React.useState(true);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [defaultWorkflowTemplate, setDefaultWorkflowTemplate] =
        React.useState<TemplateTableData>(defaultTemplateTable);

    const hasFeatureLicense = () => {
        return props.fetchedLicenses.some((license) => license.type === FEATURE_REPORTING);
    };

    const canViewReport = userSessionService.userHasAllAuthorities([AUTH_REPORT_VIEW]) && hasFeatureLicense();
    const canViewWorkflows = userSessionService.userHasAllAuthorities([
        AUTH_WORKFLOW_VIEW,
        AUTH_WORKFLOW_DEFAULT,
        AUTH_WORKFLOW_TEMPLATE_SAVE,
    ]);
    const handleCloseClick = () => {
        context?.setSubpage({
            content: undefined,
        });
    };

    const fetchOnboardings = (abortController: AbortController) => {
        onboardingsService
            .fetchOnboardings(props.product, abortController)
            .then((onboarding) => {
                if (onboarding.length > 0) {
                    setLastCompletedStep(onboarding[0].step);
                } else {
                    setLastCompletedStep("");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        fetchOnboardings(abortController);
    }, []);

    const fetchData = (abortController: AbortController) => {
        templateService
            .fetchWorkflowTemplates(
                "",
                "",
                props.product,
                "",
                [],
                [],
                [],
                true,
                "",
                "",
                "",
                false,
                "",
                true,
                abortController
            )
            .then((data) => {
                if (data?.workflowTemplatesTableData.length >= 0) {
                    setDefaultWorkflowTemplate(data.workflowTemplatesTableData[0]);
                }
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("workflowTemplatesManager.template.requestFailed"));
                }
            });
    };
    React.useEffect(() => {
        if (props.fetchedLicenses.length > 0 && canViewWorkflows) {
            fetchData(abortController);
        }
    }, [props.fetchedLicenses]);

    return (
        <div className={style.pageLayout}>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <div className={classNames(style.layoutPadding, style.pageContainer, style.page)}>
                        <div className={style.pageHeader}>
                            {props.extraHeaderText ? (
                                <div className={style.layoutTopText}>{props.extraHeaderText + " /"}</div>
                            ) : null}
                            <div className={style.headingLayout}>
                                <IconButton
                                    onClick={handleCloseClick}
                                    testId={testIds.common.subpageView.backToPrimaryViewButton}
                                >
                                    <LeftArrow color={theme.secondaryButtonForegroundColor} />
                                </IconButton>
                                <Heading className={style.headingText} tag="h1" variant="H1">
                                    {props.title}
                                </Heading>
                            </div>
                            <div className={style.layoutTopText}>{props.description}</div>
                        </div>
                        <ProgressBar
                            progressValue={
                                props.product === OnboardingProductType.BMDE ? progressValueBmde : progressValueBde
                            }
                        />
                        <div>
                            {props.product === OnboardingProductType.BDE ? (
                                <BdeSteps
                                    lastCompletedStepIndex={lastCompletedStepIndexBde}
                                    setLastCompletedStepIndex={setLastCompletedStepIndexBde}
                                    fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                                    defaultWorkflowTemplate={defaultWorkflowTemplate}
                                    requestFailureMessage={requestFailureMessage}
                                    fetchedLicenses={props.fetchedLicenses}
                                    canViewReport={canViewReport}
                                    canViewWorkflows={canViewWorkflows}
                                />
                            ) : (
                                <BmdeSteps
                                    lastCompletedStepIndex={lastCompletedStepIndexBmde}
                                    setLastCompletedStepIndex={setLastCompletedStepIndexBmde}
                                    fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                                    licenses={props.licenses}
                                    defaultWorkflowTemplate={defaultWorkflowTemplate}
                                    requestFailureMessage={requestFailureMessage}
                                    fetchedLicenses={props.fetchedLicenses}
                                    canViewReport={canViewReport}
                                    canViewWorkflows={canViewWorkflows}
                                />
                            )}
                        </div>
                        <div className={style.pageContent}>
                            <ErrorBoundary FallbackComponent={ErrorMessage}>{props.children}</ErrorBoundary>
                        </div>
                    </div>
                    {props.footerVisibility ? (
                        <div className={classNames(style.layoutPadding, style.pageFooter)}>
                            <ButtonContainer alignment="LEFT" noSpacingTop={true}>
                                {props.buttons}
                                <Button
                                    variant={props.primaryButtonRequired ? "PRIMARY" : "SECONDARY"}
                                    type={"button"}
                                    onClick={handleCloseClick}
                                    testId={testIds.common.subpageView.cancelButton}
                                >
                                    {props.primaryButtonRequired ? t("Common.ok") : t("Common.cancel")}
                                </Button>
                            </ButtonContainer>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};
