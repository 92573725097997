import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Button } from "rsuite";

import StepCompletedText from "../StepCompletedText";
import style from "components/home/bde-home-page/getting-started.scss";
import CircleNumber from "components/home/CircleWithNumber";
import Chevron from "components/icons/Chevron";
import ExportIcon from "components/icons/ExportIcon";
import HalfProgressIcon from "components/icons/HalfProgressIcon";
import Info from "components/icons/Info";
import StepThreeStatusBadgeIcon from "components/icons/StepThreeStatusBadge";
import { SuccessIcon } from "components/icons/SuccessIcon";
import arrowStyle from "components/navigation/navigation-toggle-panel.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    menuExpanded: boolean;
    stepCompleted?: boolean;
    stepActive: boolean;
    setCloseInfoDialog: (value: boolean) => void;
    setShowContent: (value: boolean) => void;
    setSuccess: (value: boolean) => void;
}
interface StepAction {
    description: string;
    label: string;
    onClick: () => void;
    variant?: "bdeUSBCreator";
}

interface Step {
    description: string;
    actions?: StepAction[];
    icon?: "";
    tooltip?: string;
}

const CreateBootableUsbDrive = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const chevronClass: string = classNames(arrowStyle.arrow, { [arrowStyle.down]: props.menuExpanded });
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [closeInfoDialog, setCloseInfoDialog] = React.useState(true);
    const [success, setSuccess] = React.useState(false);

    const handleUSBCreatorInstall = () => {
        console.error("Install USB creator clicked");
    };

    const usbCreationSteps: Step[] = [
        {
            description: "",
            actions: [
                {
                    description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.title"),
                    label: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.usbCreatorButton"),
                    onClick: handleUSBCreatorInstall,
                    variant: "bdeUSBCreator",
                },
            ],
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepTwo"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepThree"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepFour"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepFive"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepSix"),
            tooltip: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.infoIcon"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepSeven"),
        },
    ];

    return (
        <div>
            {!showContent && closeInfoDialog && (
                <div className={success ? style.successColor : style.stepsToFollowLayout}>
                    <div className={style.stepWidthAlignment}>
                        {success ? <SuccessIcon color={theme.contentBackgroundColor} /> : <StepThreeStatusBadgeIcon />}
                    </div>
                    <div className={style.stepHeading}>
                        {t("Onboarding.gettingStartedFlow.createBootableUsbDrive.title")}
                    </div>
                    <div className={classNames(style.arrow, style.stepWidthAlignment)}>
                        <Button className={style.arrowButton} onClick={() => setShowContent(true)}>
                            <Chevron color={theme.activeForegroundColor} className={chevronClass} />
                        </Button>
                    </div>
                </div>
            )}

            {showContent && (
                <div className={style.infoBox}>
                    <div className={style.subStepsToFollowLayout}>
                        <div className={style.stepWidthAlignment}>
                            <HalfProgressIcon />
                        </div>
                        <div className={style.subStepsHeading}>
                            {t("Onboarding.gettingStartedFlow.createBootableUsbDrive.title")}
                        </div>
                    </div>
                    <div className={style.stepsContainer}>
                        <div className={style.alertBox}>
                            <Info borderColor={theme.contentBackgroundColor} color={theme.sortIconActiveColor} />
                            {t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.prepareYourUSB")}
                        </div>
                        {usbCreationSteps.map((step, index) => (
                            <div key={index} className={style.subStepsToFollowLayout}>
                                <CircleNumber number={index + 1} subStep={true} />
                                {!step.actions && <div className={style.stepPadding}>{step.description}</div>}
                                {step.tooltip && (
                                    <Tooltip content={step.tooltip} placement={"top"}>
                                        <div>
                                            <Info
                                                borderColor={theme.contentBackgroundColor}
                                                color={theme.sortIconActiveColor}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                                {step.actions && step.actions.length > 0 && (
                                    <div className={classNames(style.columnLayout)}>
                                        {/* Container for columns */}
                                        {step.actions.map((action, actionIndex) => (
                                            <div key={actionIndex} className={classNames(style.actionColumn)}>
                                                <div>{action.description}</div>
                                                <button onClick={action.onClick} className={style.stepActionButton}>
                                                    {action.label}
                                                    <ExportIcon size={24} color={theme.iconFillColor} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={style.markComplete}>
                        {success ? ( // **Conditional rendering for success message**
                            <StepCompletedText
                                successText={t("Onboarding.gettingStartedFlow.createBootableUsbDrive.completed")}
                            />
                        ) : (
                            <button
                                className={classNames(buttons.primaryButtonWithoutIcon)}
                                onClick={() => {
                                    setCloseInfoDialog(true), setShowContent(false), setSuccess(true); // **Set success state**
                                }}
                            >
                                {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.markComplete")}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default connector(CreateBootableUsbDrive);
