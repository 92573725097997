import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useOutsideClick } from "components/header/MenuPanel";
import style from "components/home/bde-home-page/getting-started.scss";
import product from "components/home/product-card/product-card.scss";
import { SoftwarePackagesData } from "domain/softwarePackages";
import { StoreState } from "store";

import download from "assets/images/icons/product-icons/download.svg";

interface Props {
    titleKey: string;
    descriptionKey: string;
    onDownload: () => void;
    handleDropdown?: boolean;
    fetchedSoftwarePackageData?: SoftwarePackagesData;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const ProductCard = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const VERSION_TO_URL_MAPPING: Map<string | undefined, string> = new Map();
    props.fetchedSoftwarePackageData?.productDownloads.map((product) => {
        if (
            product.productName === t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationTool")
        ) {
            return product.versions.map((toolVersion) => {
                VERSION_TO_URL_MAPPING.set(toolVersion.version.split(" ").pop(), toolVersion.downloadUrl);
            });
        }
        return null;
    });
    const buttonRef = React.useRef(null);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const handleClickOutside = () => {
        setExpanded(false);
    };
    const versionUrlRef = useOutsideClick(handleClickOutside);

    return (
        <>
            <div className={style.productCard}>
                <div className={product.productCardWrapper} ref={versionUrlRef}>
                    <strong>{t(props.titleKey)}</strong>
                    <div
                        className={style.buttonWrapper}
                        onClick={() => {
                            setExpanded((prev) => !prev);
                        }}
                    >
                        <button
                            ref={buttonRef}
                            onClick={() => {
                                props.onDownload();
                            }}
                            aria-expanded={expanded}
                        >
                            <img src={download} alt="download" />
                        </button>
                    </div>
                    {expanded && props.handleDropdown && (
                        <div className={style.version}>
                            <div>
                                <a href={VERSION_TO_URL_MAPPING.get("Windows")}>
                                    {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.windows")}
                                </a>
                            </div>
                            <div>
                                <a href={VERSION_TO_URL_MAPPING.get("Linux")}>
                                    {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.linux")}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <div className={product.description}>{t(props.descriptionKey)}</div>
            </div>
        </>
    );
};

export default connector(ProductCard);
