import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./navigation-menu.scss";
import { BmsConfiguration } from "components/icons/BmsConfiguration";
import { Dashboard } from "components/icons/Dashboard";
import { Home } from "components/icons/Home";
import { Learn } from "components/icons/Learn";
import { Licenses } from "components/icons/Licenses";
import { OnPremBmp } from "components/icons/OnPremBmp";
import PremiumFeatureIcon from "components/icons/PremiumFeatureIcon";
import { Reports } from "components/icons/Reports";
import { Sustainability } from "components/icons/Sustainability";
import { Tenants } from "components/icons/Tenants";
import { Users } from "components/icons/Users";
import { Workflows } from "components/icons/Workflows";
import { NavigationMenuItem } from "components/navigation/navigation-menu-item/NavigationMenuItem";
import NavigationTogglePanel from "components/navigation/NavigationTogglePanel";
import sideNavStyle from "components/navigation/side-navigation.scss";
import {
    CONFIGURATION_BMS_LICENSES_ROUTE,
    DASHBOARD_ROUTE,
    HOME_ROUTE,
    LICENSES_EXTRACTION_ROUTE,
    LICENSES_ROUTE,
    MCS_ROUTE,
    OUR_IMPACT_DASHBOARD_ROUTE,
    REPORTS_OUR_VIEWS_ROUTE,
    REPORTS_ROUTE,
    RouteDefinition,
    SUPPORT_ROUTE,
    SUSTAINABILITY_DASHBOARD_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { FLAG_NEW_NAVIGATION } from "services/feature/FeatureFlagService";
import { StoreState } from "store";

import iconPrivateView from "assets/images/icons/icon_private_view.svg";

interface MainMenuItem extends Partial<RouteDefinition> {
    title: string;
    childRoutes: RouteDefinition[];
    icon: JSX.Element;
    mainRoute?: RouteDefinition;
}

type MenuRoute = MainMenuItem | RouteDefinition;
const isMainMenuItem = (route: MenuRoute): route is MainMenuItem => "mainRoute" in route;

const NavigationMenu = () => {
    const { t } = useTranslation();
    const currentPath = window.location.pathname + window.location.search;
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const getTooltip = (route: RouteDefinition, disabled: boolean): JSX.Element => {
        const routes = new Map([
            [
                OUR_IMPACT_DASHBOARD_ROUTE,
                {
                    paid: t("Routes.ourImpact.tooltip.paid"),
                    title: t("Routes.ourImpact.tooltip.unpaid.title"),
                    body: t("Routes.ourImpact.tooltip.unpaid.calculate"),
                    footer: t("Routes.ourImpact.tooltip.unpaid.contactBlancco"),
                },
            ],
            [
                WORKFLOWS_ROUTE,
                {
                    paid: t("Routes.workflowsMenu.paidWorkflowTooltip"),
                    title: t("Routes.workflowsMenu.unpaidTitle"),
                    body: t("Routes.workflowsMenu.automateWorkflowsAndSimplifyCompliance"),
                    footer: t("Routes.workflowsMenu.contactBlancco"),
                },
            ],
        ]);
        const dto = routes.get(route);
        if (dto == null) {
            return <></>;
        }
        if (!disabled) {
            return <div className={style.tooltip}>{dto.paid}</div>;
        }
        return (
            <div className={style.tooltip}>
                <div className={style.title}>{dto.title}</div>
                <div className={style.content}>{dto.body}</div>
                <div>{dto.footer}</div>
            </div>
        );
    };

    const hasActiveLink = (routes: RouteDefinition[]): boolean => {
        const isRouteOrChildActive = (route: RouteDefinition): boolean => {
            if (route.path === currentPath) {
                return true;
            }

            return route.childRoutes.some((childRoute) => isRouteOrChildActive(childRoute));
        };

        return routes.some((route) => isRouteOrChildActive(route));
    };

    const getIconColor = (route: RouteDefinition) => {
        const isRouteActive = currentPath === route.path;
        const isChildActive = hasActiveLink(route.childRoutes);

        return isRouteActive || isChildActive ? theme.textColor : theme.iconFillColor;
    };

    // TODO BCC-3711: CONFIGURATION_BMS_LICENSES_ROUTE is enabled as part of license routes in BlanccoCommonCloud.tsx.
    // Check that the route here follows the same visibility logic.
    const mobileMenuItems: MainMenuItem[] = [
        {
            title: HOME_ROUTE.title,
            mainRoute: HOME_ROUTE,
            childRoutes: [],
            icon: <Home color={getIconColor(HOME_ROUTE)} />,
        },
        {
            title: DASHBOARD_ROUTE.title,
            mainRoute: DASHBOARD_ROUTE,
            childRoutes: [],
            icon: <Dashboard color={getIconColor(DASHBOARD_ROUTE)} />,
        },
        {
            title: SUSTAINABILITY_DASHBOARD_ROUTE.title,
            mainRoute: SUSTAINABILITY_DASHBOARD_ROUTE,
            childRoutes: [],
            icon: <Sustainability color={getIconColor(SUSTAINABILITY_DASHBOARD_ROUTE)} />,
        },
        {
            title: REPORTS_ROUTE.title,
            mainRoute: REPORTS_ROUTE,
            childRoutes: [],
            icon: <Reports color={getIconColor(REPORTS_ROUTE)} />,
        },
        {
            title: WORKFLOWS_ROUTE.title,
            mainRoute: WORKFLOWS_ROUTE,
            childRoutes: [],
            icon: <Workflows color={getIconColor(WORKFLOWS_ROUTE)} />,
        },
        {
            title: LICENSES_ROUTE.title,
            mainRoute: LICENSES_ROUTE,
            childRoutes: [],
            icon: <Licenses color={getIconColor(LICENSES_ROUTE)} />,
        },
        {
            title: USERS_ROUTE.title,
            mainRoute: USERS_ROUTE,
            childRoutes: [],
            icon: <Users color={getIconColor(USERS_ROUTE)} />,
        },
        {
            title: TENANTS_ROUTE.title,
            mainRoute: TENANTS_ROUTE,
            childRoutes: [],
            icon: <Tenants color={getIconColor(TENANTS_ROUTE)} />,
        },
        {
            title: MCS_ROUTE.title,
            mainRoute: MCS_ROUTE,
            childRoutes: [],
            icon: <OnPremBmp color={getIconColor(MCS_ROUTE)} />,
        },
        {
            title: CONFIGURATION_BMS_LICENSES_ROUTE.title,
            mainRoute: CONFIGURATION_BMS_LICENSES_ROUTE,
            childRoutes: [],
            icon: <BmsConfiguration color={getIconColor(CONFIGURATION_BMS_LICENSES_ROUTE)} />,
        },
        {
            title: SUPPORT_ROUTE.title,
            mainRoute: SUPPORT_ROUTE,
            childRoutes: [],
            icon: <Learn color={getIconColor(SUPPORT_ROUTE)} />,
        },
    ];

    const getMenuItem = (item: MainMenuItem) => mobileMenuItems.find((menuItem) => menuItem.title === item.title);

    const createMenuItems = (route: RouteDefinition, index: number) => {
        let disabled = false;
        const flag = route.getFeatureFlag();

        if (flag != null && !useFeature(flag)) {
            return null;
        }

        if (!route.isAccessibleTo(tenantDetails)) {
            if (!route.advertised) {
                return null;
            }
            disabled = true;
        }

        if (!route.isValidUser(user)) {
            return null;
        }

        if (route === REPORTS_OUR_VIEWS_ROUTE || route === LICENSES_EXTRACTION_ROUTE) {
            return (
                <li>
                    <NavigationTogglePanel
                        isOpen={hasActiveLink(route.childRoutes)}
                        id={route.title}
                        openId={hasActiveLink(route.childRoutes) ? route.title : undefined}
                        title={t(route.title)}
                        toggleButtonTestId={route.testId}
                        className={style.customViewsMenu}
                    >
                        <Menu>
                            <MenuItem className={style.menuItem}>
                                <ul>
                                    {route.childRoutes.map((childRoute, index) => createMenuItems(childRoute, index))}
                                </ul>
                            </MenuItem>
                        </Menu>
                    </NavigationTogglePanel>
                </li>
            );
        }

        if (route.childRoutes && route.childRoutes.length > 0 && !disabled) {
            return <li key={route.path}>{createMenuStructure(route, index)}</li>;
        }

        return (
            <li key={route.path} data-index={index}>
                {disabled ? (
                    <div className={classNames(style.navItem, style.inActive)} data-testid={route.testId}>
                        <div>{t(route.title)}</div>
                        <Tooltip maxWidth={218} content={getTooltip(route, disabled)} placement="top-start">
                            <div className={style.icon}>
                                <PremiumFeatureIcon color={theme.iconFillColor} />
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <NavigationMenuItem as="link" to={route.path} testId={route.testId}>
                        {t(route.title)}
                        {route.advertised && (
                            <Tooltip content={getTooltip(route, disabled)} placement="top">
                                <div className={style.icon}>
                                    <div className={style.purchased} />
                                </div>
                            </Tooltip>
                        )}
                        {route.isShared === false && (
                            <Tooltip content={t("CreateReportView.privateView.title")} placement="top">
                                <div className={style.icon}>
                                    <img
                                        className={sideNavStyle.iconPrivateView}
                                        src={iconPrivateView}
                                        alt={t("AltText.privateView")}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </NavigationMenuItem>
                )}
            </li>
        );
    };

    const createMenuStructure = (route: MenuRoute, index?: number) => {
        const routeIcon = isMainMenuItem(route) ? route.icon : undefined;
        const currentRoute = isMainMenuItem(route) ? getMenuItem(route)?.mainRoute ?? route : route;
        const isTopLevelLink = currentRoute.childRoutes.length <= 1;

        return (
            <ul key={route.title}>
                {isTopLevelLink ? (
                    <li>
                        <NavigationMenuItem
                            as="link"
                            to={currentRoute.path ?? ""}
                            testId={currentRoute.testId}
                            activeClassName={"active"}
                            data-depth={index ?? 0}
                            exact
                        >
                            {routeIcon}
                            {t(route.title)}
                        </NavigationMenuItem>
                    </li>
                ) : (
                    <li>
                        <NavigationMenuItem
                            as="link"
                            to={currentRoute.path ?? ""}
                            testId={currentRoute.testId}
                            activeClassName={"active"}
                            data-depth={index ?? 0}
                            exact
                        >
                            {routeIcon}
                            {t(route.title)}
                        </NavigationMenuItem>

                        {hasActiveLink(currentRoute.childRoutes) && (
                            <ul className={style.menu}>
                                {currentRoute.childRoutes.map((childRoute, index) =>
                                    createMenuItems(childRoute, index)
                                )}
                            </ul>
                        )}
                    </li>
                )}
            </ul>
        );
    };

    return (
        <nav
            className={classNames(style.navigationMenu, {
                [style.mainNavigation]: useFeature(FLAG_NEW_NAVIGATION),
            })}
        >
            {mobileMenuItems.map((item) => createMenuStructure(item))}
        </nav>
    );
};

export default NavigationMenu;
