import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { subpageContext } from "./ApplicationLayout";
import style from "./layout.scss";
import { getFeatureLicense } from "components/licenses/common";
import Modal from "components/modal/Modal";
import { DELIVERYHISTORY_LICENSES_ROUTE } from "components/router/Routes";
import EditTenantView from "components/tenants/edit-tenant/EditTenantView";
import tenantStyle from "components/tenants/tenants.scss";
import Tooltip from "components/tooltip/Tooltip";
import { restoreUrls } from "services/login/endpointRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getTenantLayerList,
    getTenantName,
    getTenantUuid,
    hasSubTenantCookie,
    hasTenantCookie,
    removeTenantCookie,
    updateTenantName,
    updateTenantUuid,
} from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import { clearAllTenantDetails, popTenantDetails, pushTenantDetails, updateTenantDetails } from "store/tenantDetails";
import { switchTheme } from "store/theme";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
    handleCookie?: boolean;
}
const MAP_STATE = (state: StoreState) => {
    const user = state.userReducer.user;
    const username = user === null ? "" : user.username.split("@")[0];
    return { user, username };
};
const MAP_DISPATCH = { popTenantDetails, pushTenantDetails, updateTenantDetails, clearAllTenantDetails, switchTheme };
const CONNECTOR = connect(MAP_STATE, MAP_DISPATCH, null, { forwardRef: true });

const DropTenantAccessBanner = React.forwardRef<HTMLDivElement, ConnectedProps<typeof CONNECTOR>>((props, ref) => {
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
        handleCookie: false,
    });
    const [licenceErrorVisible, setLicenceErrorVisible] = React.useState(false);
    const checkFeatureLicense = getFeatureLicense();
    const context = React.useContext(subpageContext);
    const dropAccessHandler = () => {
        props.clearAllTenantDetails();
        props.user &&
            props.pushTenantDetails({
                uuid: props.user.tenantUuid,
                featureLicenses: props.user.featureLicenses,
                region: props.user.region,
                type: props.user.tenantType,
                tenantName: props.username,
                loginMethod: props.user.tenantLoginMethod,
                tenantTier: props.user.tier,
                licensingModel: props.user.licensingModel,
            });
        removeTenantCookie();
        restoreUrls();
        window.location.replace("/customers/all");
    };

    const dropSubTenant = (tenantUuid: string) => {
        const index = getTenantLayerList().findIndex((item) => {
            return item.uuid === tenantUuid;
        });
        updateTenantUuid(getTenantLayerList()[index].uuid);
        updateTenantName(getTenantLayerList()[index].tenantName);
        const list = getTenantLayerList().splice(index + 1, getTenantLayerList().length - index);
        list.forEach(() => {
            props.popTenantDetails();
        });
        accessTenant(tenantUuid, true);
    };

    const editTenantHandler = () => {
        usageStatisticsService.sendEvent({
            category: Category.TENANT,
            action: Action.EDIT_TENANT,
        });
        if (hasTenantCookie() || hasSubTenantCookie()) {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            tenantService
                .fetchTenant(getTenantUuid(), abortController)
                .then((tenantDetails) => {
                    context?.setSubpage({
                        content: (
                            <EditTenantView
                                customerName={tenantDetails.name}
                                status={tenantDetails.status}
                                tier={tenantDetails.tier}
                                uuid={tenantDetails.uuid}
                                tenantRegion={tenantDetails.region}
                                expirationDate={tenantDetails.expirationDate}
                                countryCode={tenantDetails.countryCode}
                                contactName={tenantDetails.contactName}
                                contactEmail={tenantDetails.contactEmail}
                                notes={tenantDetails.notes}
                                salesforceAccountId={tenantDetails.salesforceAccountId}
                                parentExpirationDate={tenantDetails.parentExpirationDate}
                                tenantType={tenantDetails.type}
                                licensingModel={tenantDetails.licensingModel}
                                inTenantAccess={true}
                                updateSubTenant={{ updateCookie, dropAccessHandler }}
                                parentLicensingModel={tenantDetails.parentLicensingModel}
                                parentType={tenantDetails.parentType}
                                parentTier={tenantDetails.parentTier}
                                parentDrawbackPrevention={tenantDetails.parentDrawbackPrevention}
                            />
                        ),
                        isModal: false,
                    });
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        context?.setSubpage({
                            content: undefined,
                        });
                    }
                });
        }
    };

    const accessTenant = (tenantUuid: string, isPushTenant: boolean) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .accessTenant(tenantUuid, abortController)
            .then((data) => {
                if (isPushTenant) {
                    props.updateTenantDetails({
                        uuid: tenantUuid,
                        featureLicenses: data.featureLicenses,
                        region: data.region,
                        type: data.type,
                        tenantName: data.tenantName,
                        loginMethod: data.loginMethod,
                        tenantTier: data.tenantTier,
                        licensingModel: data.licensingModel,
                        parentDrawBackPrevention: data.parentDrawbackPrevention,
                    });
                } else {
                    window.location.reload();
                }
                const checkIfFeatureLicensePresent: boolean = checkFeatureLicense.has(window.location.pathname)
                    ? data.featureLicenses.some((item) => checkFeatureLicense.get(window.location.href)?.includes(item))
                    : true;
                window.location.replace(
                    !checkIfFeatureLicensePresent ? DELIVERYHISTORY_LICENSES_ROUTE.path : window.location.href
                );
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setLicenceErrorVisible(true);
                }
            });
    };

    const updateCookie = () => {
        hideResult();
        const tenantUuid = getTenantUuid();
        accessTenant(tenantUuid, true);
    };

    const hideResult = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false, handleCookie: false });
    };

    const displayTenantBreadCrumb = (tenantName: string, uuid: string, edit: boolean) => {
        return edit ? (
            <button
                className={style.editButton}
                key={uuid}
                onClick={() => editTenantHandler()}
                data-testid={testIds.tenantAccess.hierarchy.editTenantButton}
            >
                {t("EditCustomerView.title", { customerName: tenantName })}
            </button>
        ) : (
            <>
                <Tooltip
                    content={t("DropCustomerAccessBanner.dropAccessMessage", {
                        customerName: tenantName,
                    })}
                >
                    <a
                        key={uuid}
                        href="#"
                        onClick={() => {
                            props.username == tenantName ? dropAccessHandler() : dropSubTenant(uuid);
                        }}
                        data-testid={testIds.tenantAccess.hierarchy.dropAccessLink}
                    >
                        {tenantName}
                    </a>
                </Tooltip>
                {"  / "}
            </>
        );
    };

    return (
        <>
            <div className={style.wholeBanner} data-testid={testIds.tenantAccess.itself} ref={ref}>
                <div className={style.tenantEditLinkAlignment} data-testid={testIds.tenantAccess.hierarchy.itself}>
                    {getTenantLayerList().length > 0
                        ? getTenantLayerList().map((each, index) => {
                              const editFlag = getTenantLayerList().length - 1 == index;
                              return displayTenantBreadCrumb(each.tenantName, each.uuid, editFlag);
                          })
                        : ""}
                </div>
                <div className={style.dropTenantAlignment}>
                    {t("DropCustomerAccessBanner.message", { customerName: getTenantName() })}
                    <a href="#" onClick={() => dropAccessHandler()} data-testid={testIds.tenantAccess.dropAccessLink}>
                        {t("DropCustomerAccessBanner.dropAccess")}
                    </a>
                    {t("DropCustomerAccessBanner.dropAccessToViewRootUser", { customerName: props.username })}
                </div>
            </div>
            <Modal
                isOpen={result.resultVisible}
                hideModal={result.handleCookie ? updateCookie : hideResult}
                modalTitle={result.title}
            >
                <div className={tenantStyle.resultContainer}>{result.message}</div>
                <div className={tenantStyle.buttonContainer}>
                    <button
                        className={buttons.primaryOkButton}
                        onClick={result.handleCookie ? updateCookie : hideResult}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={licenceErrorVisible}
                hideModal={dropAccessHandler}
                modalTitle={t("EditCustomerView.editCustomerLicenceTitle")}
            >
                <div className={tenantStyle.errorContainer}>
                    {t("EditCustomerView.licenseFailureMessage", { customerName: getTenantName() })}
                </div>
                <div className={tenantStyle.buttonContainer}>
                    <button
                        className={buttons.primaryOkButton}
                        onClick={dropAccessHandler}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
});

export default CONNECTOR(DropTenantAccessBanner);
