import classNames from "classnames";
import React from "react";
import { Button } from "react-aria-menubutton";
import { NavLink, NavLinkProps } from "react-router-dom";

import style from "./navigation-menu-item.scss";

interface BaseProps {
    testId?: string;
    className?: string;
    children: React.ReactNode;
}

interface ButtonProps extends BaseProps {
    as: "button";
    isActive?: boolean;
}

interface LinkProps extends BaseProps, Omit<NavLinkProps, "children" | "className"> {
    as: "link";
}

type Props = ButtonProps | LinkProps;
const isLinkProps = (props: Props): props is LinkProps => props.as === "link";

export const NavigationMenuItem = (props: Props) => {
    const classes = classNames(
        style.navigationMenuItem,
        {
            // active is NavLink's built-in class. Using the same here for consistency.
            ["active"]: props.isActive,
        },
        props.className
    );

    if (isLinkProps(props)) {
        return (
            <NavLink {...props} to={props.to} data-testid={props.testId} className={classes}>
                {props.children}
            </NavLink>
        );
    }
    return (
        <Button {...props} className={classes} data-testid={props.testId}>
            {props.children}
        </Button>
    );
};
