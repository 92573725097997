import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { ParameterQuery } from "utils/commonFunctions";

const PATH_ONBOARDINGS_API = "/api/v1/onboardings";

export interface OnboardingDto {
    user_uuid?: string;
    product: string;
    workflow_template_uuid?: string;
    step: string;
}

export interface Onboarding {
    userUuid?: string;
    product: string;
    workflowTemplateUuid?: string;
    step: string;
}

function toOnboardingDto(dto: OnboardingDto): Onboarding {
    return {
        userUuid: dto.user_uuid,
        product: dto.product,
        workflowTemplateUuid: dto.workflow_template_uuid,
        step: dto.step,
    };
}

export class OnboardingsService {
    public async fetchOnboardings(product: string, abortController?: AbortController) {
        return apiGatewayService
            .invokeApi(
                PATH_ONBOARDINGS_API + new ParameterQuery().add("product", product).createQueryString(),
                "GET",
                null,
                {
                    abortSignal: abortController?.signal,
                    apiType: ApiType.ARTHUR,
                    includeCredentials: true,
                }
            )
            .then((onboardingsList: OnboardingDto[]) => {
                return onboardingsList.map(toOnboardingDto);
            });
    }

    public saveOnboardingStep(steps: Onboarding, abortController?: AbortController): Promise<void> {
        const dto: OnboardingDto = {
            product: steps.product,
            step: steps.step,
            workflow_template_uuid: "077067a0-a7af-46c9-8535-17f7a0bef4c9",
        };
        return apiGatewayService.invokeApi(PATH_ONBOARDINGS_API, "POST", dto, {
            abortSignal: abortController?.signal,
            apiType: ApiType.ARTHUR,
            includeCredentials: true,
        });
    }
}

export const onboardingsService = new OnboardingsService();
