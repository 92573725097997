import classNames from "classnames";
import React from "react";
import { Menu } from "react-aria-menubutton";

import style from "components/dropdown-menu/dropdown-menu.scss";

interface Props {
    menuItems: JSX.Element[];
    /** Positions dropdown in relation to its parent element. */
    position?: Position;
    className?: string;
    testId?: string;
}

type Position = "LEFT" | "RIGHT" | "AUTO";

export const DropdownMenuDivider = () => <hr className={style.dropdownMenuDivider} />;

export const DropdownMenu = (props: Props) => {
    const dropdownMenuClasses = classNames(
        style.dropdownMenu,
        {
            [style.positionLeft]: props.position === "LEFT",
            [style.positionAuto]: props.position === "AUTO",
        },
        props.className
    );

    return (
        <Menu className={dropdownMenuClasses} data-testid={props.testId}>
            <ul>
                {props.menuItems.map((item, key) => (
                    <li className={style.dropdownMenuItem} key={key}>
                        {item}
                    </li>
                ))}
            </ul>
        </Menu>
    );
};
