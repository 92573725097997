import * as React from "react";

interface Props {
    color?: string;
}

export const ManualIcon = React.forwardRef<SVGSVGElement, Props>(
    (props, ref): JSX.Element => (
        <svg ref={ref} width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 15.0005H18.5952C18.8188 15.0005 19 14.8193 19 14.5958V3.40529C19 3.18174 18.8188 3.00052 18.5952 3.00052H17M10 15.0005H1.40476C1.18122 15.0005 1 14.8193 1 14.5958V3.40487C1 3.18128 1.18128 3.00005 1.40487 3.00011L3 3.00052M10 15.0005V4.11111M10 15.0005C10 14.3817 9.77875 13.7877 9.38492 13.3501C8.9911 12.9125 8.45695 12.6667 7.9 12.6667H3V1H7.2C7.94261 1 8.6548 1.32778 9.1799 1.91122C9.705 2.49467 10 3.28599 10 4.11111M10 15.0005C10 14.3817 10.2212 13.7877 10.6151 13.3501C11.0089 12.9125 11.543 12.6667 12.1 12.6667H17V1H12.8C12.0574 1 11.3452 1.32778 10.8201 1.91122C10.295 2.49467 10 3.28599 10 4.11111"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </svg>
    )
);
