import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
    ActiveWorkflowDialog,
    ConflictsResponse,
    getTemplateTypeBadge,
    TemplateDetailsDialog,
} from "./PublishedWorkflowTemplate";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import { TitleIcon } from "components/icons/TitleIcon";
import WarningYellowNotificationIcon from "components/icons/WarningYellowNotificationIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import Tooltip from "components/tooltip/Tooltip";
import WasmVisualEditorView from "components/workflows/wasm-visual-editor/WasmVisualEditorView";
import publishTemplateStyle from "components/workflows/workflow-templates/published-workflow-template.scss";
import { deduceEditorVersion } from "components/workflows/WorkflowUtil";
import { AUTH_WORKFLOW_DEFAULT, AUTH_WORKFLOW_TEMPLATE_SAVE } from "domain/authority";
import { userSessionService } from "services/user/UserSessionService";
import { ManifestWorkflowEditor, Profile, profileToNameMap } from "services/workflows/WorkflowService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    showTemplateDetailsDialog: TemplateDetailsDialog;
    setShowTemplateDetailsDialog: React.Dispatch<React.SetStateAction<TemplateDetailsDialog>>;
    setAddedBadge: React.Dispatch<React.SetStateAction<boolean>>;
    setCount?: React.Dispatch<React.SetStateAction<number>>;
    requestFailureMessage: string;
    loading: boolean;
    conflictsException: ConflictsResponse | undefined;
    addedBadge: boolean;
    saveTemplate: () => void;
    showActiveWorkflowDialog: ActiveWorkflowDialog;
    setShowActiveWorkflowDialog: React.Dispatch<React.SetStateAction<ActiveWorkflowDialog>>;
    setSavedWorkflowActive: () => void;
    showSuccessNotificationToast: (active: boolean) => void;
    activeWorkflowLoading: boolean;
    workflowEditors: ManifestWorkflowEditor[];
    previewOnly: boolean;
}

const DOT = " • ";
// Workflow template feature introduced from BDE 7.14.0 and BMDE 5.5.0 which includes tabs in preview mode.
const BDE_DEDUCED_OLDER_EDITOR_VERSION = "7.14.0";
const BMDE_DEDUCED_OLDER_EDITOR_VERSION = "5.6.0";

const workflowTemplateDetailsDialog = (props: Props) => {
    const { t } = useTranslation();
    const [saveInProgress, setSaveInProgress] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    React.useEffect(() => {
        !props.loading && setSaveInProgress(false);
    }, [props.loading]);

    const previewEditorCssClass =
        props.showTemplateDetailsDialog.details &&
        [BDE_DEDUCED_OLDER_EDITOR_VERSION, BMDE_DEDUCED_OLDER_EDITOR_VERSION].includes(
            deduceEditorVersion(props.showTemplateDetailsDialog.details.editorVersion)
        )
            ? publishTemplateStyle.previewWorkflow
            : publishTemplateStyle.previewWorkflowWithBorder;

    return (
        <>
            <Modal
                isOpen={props.showTemplateDetailsDialog.show}
                hideModal={() => {
                    props.setShowTemplateDetailsDialog({ show: false, details: undefined });
                    props.setAddedBadge(false);
                    props.setCount?.((prev) => ++prev);
                }}
                titleIcon={props.requestFailureMessage ? <TitleIcon iconType="ERROR" /> : ""}
                modalTitle={
                    props.requestFailureMessage ? t("workflowTemplate.workflowTemplateDetailsDialog.error.title") : ""
                }
            >
                <div
                    className={
                        saveInProgress
                            ? publishTemplateStyle.saveInProgress
                            : !props.requestFailureMessage
                            ? publishTemplateStyle.publishedTemplateDetailsDialog
                            : publishTemplateStyle.publishedTemplateDetailsErrorDialog
                    }
                >
                    {props.loading ? (
                        <div className={publishTemplateStyle.saveTemplate}>
                            <LoadingIndicator />
                            <div className={publishTemplateStyle.message}>
                                {t("workflowTemplate.workflowTemplateDetailsDialog.addingTemplateToWorkflows")}
                            </div>
                        </div>
                    ) : props.requestFailureMessage ? (
                        <>
                            <div>{props.requestFailureMessage}</div>
                            {props.conflictsException && (
                                <div className={publishTemplateStyle.conflictingSubWorkflows}>
                                    <ul>
                                        {props.conflictsException.conflictingWorkflowNames.map((each, index) => (
                                            <li key={index}>{each}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <ButtonContainer>
                                <Button
                                    variant={"PRIMARY"}
                                    onClick={() => window.location.replace(WORKFLOWS_ROUTE.path)}
                                    testId={testIds.common.dialog.primaryButton}
                                >
                                    {t("workflowTemplate.workflowTemplateDetailsDialog.error.goToWorkflows")}
                                </Button>

                                <Button
                                    variant={"SECONDARY"}
                                    onClick={() => {
                                        props.setShowTemplateDetailsDialog({ show: false, details: undefined });
                                        props.setAddedBadge(false);
                                        props.setCount?.((prev) => ++prev);
                                    }}
                                    testId={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.cancel")}
                                </Button>
                            </ButtonContainer>
                        </>
                    ) : (
                        props.showTemplateDetailsDialog.details && (
                            <div className={publishTemplateStyle.template}>
                                <div className={publishTemplateStyle.templateContent}>
                                    <div className={publishTemplateStyle.badges}>
                                        <div>
                                            {getTemplateTypeBadge(
                                                props.showTemplateDetailsDialog.details.templateType,
                                                t,
                                                theme.readyToUseSkyBlueColor,
                                                theme.modularPurpleColor
                                            )}
                                        </div>
                                        {props.showTemplateDetailsDialog.details.update ? (
                                            <div className={publishTemplateStyle.added}>
                                                <StatusBadge
                                                    values={[
                                                        {
                                                            status: Status.WARNING,
                                                            title: t(
                                                                "workflowTemplate.workflowTemplateDetailsDialog.update"
                                                            ),
                                                        },
                                                    ]}
                                                    tooltip={true}
                                                />
                                            </div>
                                        ) : (
                                            (props.addedBadge || props.showTemplateDetailsDialog.details.saved) && (
                                                <div className={publishTemplateStyle.added}>
                                                    <StatusBadge
                                                        values={[
                                                            {
                                                                status: Status.SUCCESS,
                                                                title: t(
                                                                    "workflowTemplate.workflowTemplateDetailsDialog.added"
                                                                ),
                                                            },
                                                        ]}
                                                        tooltip={true}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>

                                    <div className={publishTemplateStyle.dataContainer}>
                                        <div className={publishTemplateStyle.title}>
                                            {props.showTemplateDetailsDialog.details.name}
                                        </div>
                                        <div className={publishTemplateStyle.textstyle}>
                                            {profileToNameMap.get(
                                                props.showTemplateDetailsDialog.details.profile as Profile
                                            )}{" "}
                                            {props.showTemplateDetailsDialog.details.editorVersion}{" "}
                                            {props.showTemplateDetailsDialog.details.connectedWorkflows
                                                .totalConnectedWorkflows !== 0 &&
                                                DOT +
                                                    props.showTemplateDetailsDialog.details.connectedWorkflows
                                                        .totalConnectedWorkflows +
                                                    t("workflowTemplate.subWorkflows")}
                                        </div>
                                        {props.showTemplateDetailsDialog.details.useCases.length > 0 ||
                                        props.showTemplateDetailsDialog.details.coreActivities.length > 0 ||
                                        props.showTemplateDetailsDialog.details.processors.length > 0 ? (
                                            <div className={publishTemplateStyle.categories}>
                                                <ul>
                                                    {props.showTemplateDetailsDialog.details.useCases.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                    {props.showTemplateDetailsDialog.details.coreActivities.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                    {props.showTemplateDetailsDialog.details.processors.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <div className={publishTemplateStyle.details}>
                                            {props.showTemplateDetailsDialog.details.description && (
                                                <div className={publishTemplateStyle.description}>
                                                    <div className={publishTemplateStyle.title}>
                                                        {t(
                                                            "workflowTemplate.workflowTemplateDetailsDialog.description"
                                                        )}
                                                    </div>
                                                    <div className={publishTemplateStyle.content}>
                                                        {props.showTemplateDetailsDialog.details.description}
                                                    </div>
                                                </div>
                                            )}
                                            {props.showTemplateDetailsDialog.details.connectedWorkflows.subWorkflows
                                                .length ||
                                            props.showTemplateDetailsDialog.details.connectedWorkflows
                                                .runProcessFlowWorkflows.length ||
                                            props.showTemplateDetailsDialog.details.connectedWorkflows
                                                .gradingPanelWorkflows.length ||
                                            props.showTemplateDetailsDialog.details.connectedWorkflows.collectWorkflows
                                                .length ? (
                                                <div className={publishTemplateStyle.description}>
                                                    <div className={publishTemplateStyle.title}>
                                                        {t("workflowTemplate.subWorkflows")}
                                                    </div>
                                                    <div className={publishTemplateStyle.content}>
                                                        <ul>
                                                            {props.showTemplateDetailsDialog.details.connectedWorkflows.subWorkflows.map(
                                                                (data: string, index: number) => (
                                                                    <li
                                                                        className={publishTemplateStyle.item}
                                                                        key={index}
                                                                    >
                                                                        {data}
                                                                    </li>
                                                                )
                                                            )}
                                                            {props.showTemplateDetailsDialog.details.connectedWorkflows.runProcessFlowWorkflows.map(
                                                                (data: string, index: number) => (
                                                                    <li
                                                                        className={publishTemplateStyle.item}
                                                                        key={index}
                                                                    >
                                                                        {data}
                                                                    </li>
                                                                )
                                                            )}
                                                            {props.showTemplateDetailsDialog.details.connectedWorkflows.runDiagnoseWorkflows.map(
                                                                (data: string, index: number) => (
                                                                    <li
                                                                        className={publishTemplateStyle.item}
                                                                        key={index}
                                                                    >
                                                                        {data}
                                                                    </li>
                                                                )
                                                            )}
                                                            {props.showTemplateDetailsDialog.details.connectedWorkflows.gradingPanelWorkflows.map(
                                                                (data: string, index: number) => (
                                                                    <li
                                                                        className={publishTemplateStyle.item}
                                                                        key={index}
                                                                    >
                                                                        {data}
                                                                    </li>
                                                                )
                                                            )}
                                                            {props.showTemplateDetailsDialog.details.connectedWorkflows.collectWorkflows.map(
                                                                (data: string, index: number) => (
                                                                    <li
                                                                        className={publishTemplateStyle.item}
                                                                        key={index}
                                                                    >
                                                                        {data}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    {!props.addedBadge &&
                                        !props.previewOnly &&
                                        userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_SAVE]) && (
                                            <div className={publishTemplateStyle.actionButtons}>
                                                <ButtonContainer>
                                                    <Button
                                                        variant={"PRIMARY"}
                                                        onClick={() => {
                                                            setSaveInProgress(true);
                                                            props.saveTemplate();
                                                        }}
                                                        testId={testIds.common.dialog.primaryButton}
                                                    >
                                                        {t(
                                                            "workflowTemplate.workflowTemplateDetailsDialog.addTemplate"
                                                        )}
                                                    </Button>
                                                    {props.showTemplateDetailsDialog.details.update && (
                                                        <Tooltip
                                                            content={t(
                                                                "workflowTemplate.workflowTemplateDetailsDialog.tooltip.updateWarning"
                                                            )}
                                                        >
                                                            <div>
                                                                {" "}
                                                                <WarningYellowNotificationIcon />
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </ButtonContainer>
                                            </div>
                                        )}
                                </div>
                                <div className={previewEditorCssClass}>
                                    <WasmVisualEditorView
                                        key={props.showTemplateDetailsDialog.details.uuid}
                                        profile={props.showTemplateDetailsDialog.details.profile}
                                        version={props.showTemplateDetailsDialog.details.editorVersion}
                                        workflowEditors={props.workflowEditors}
                                        uuid={props.showTemplateDetailsDialog.details.uuid}
                                        data-testid={
                                            testIds.workArea.workflows.manageWorkflowDialog.visualEditor.itself
                                        }
                                        actionType={"create"}
                                        entityType={"TEMPLATE"}
                                        preview={true}
                                        preventNameChange={true}
                                    />
                                </div>
                            </div>
                        )
                    )}
                </div>
            </Modal>
            <Modal
                modalTitle={t("workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.title")}
                isOpen={
                    props.showActiveWorkflowDialog.show &&
                    userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_DEFAULT])
                }
                hideModal={() => {
                    props.showSuccessNotificationToast(false);
                    props.setShowActiveWorkflowDialog({
                        show: false,
                        workflowUuid: "",
                    });
                }}
            >
                {props.activeWorkflowLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div className={publishTemplateStyle.setActiveDialog}>
                        <div>{t("workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.message")}</div>

                        <ButtonContainer>
                            <Button
                                variant={"PRIMARY"}
                                onClick={() => {
                                    props.showSuccessNotificationToast(false);
                                    props.setShowActiveWorkflowDialog({
                                        show: false,
                                        workflowUuid: "",
                                    });
                                }}
                                testId={testIds.common.dialog.closeButton}
                            >
                                {t(
                                    "workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.dontSetActiveButton"
                                )}
                            </Button>

                            <Button
                                variant={"SECONDARY"}
                                onClick={() => {
                                    props.setSavedWorkflowActive();
                                }}
                                testId={testIds.common.confirmationDialog.confirmButton}
                            >
                                {t(
                                    "workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.setAsActiveButton"
                                )}
                            </Button>
                        </ButtonContainer>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default workflowTemplateDetailsDialog;
