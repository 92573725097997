import { McsResponseDto, McTable, toMcsData } from "domain/mc";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

const API_PATH = "/api/v1/mcs";

export interface RegistrationTokenDto {
    token: string;
}

export interface RegistrationToken {
    token: string;
}

function toRegistrationToken(dto: RegistrationTokenDto): RegistrationToken {
    return {
        token: dto.token,
    };
}

class HybridMcService {
    public createMcRegistrationToken(): Promise<RegistrationToken> {
        return apiGatewayService
            .invokeApi("/api/v1/mcs/register", "POST", null, {
                apiType: ApiType.OLIVER,
            })
            .then((dto: RegistrationTokenDto) => toRegistrationToken(dto));
    }

    public async fetchData(abortController: AbortController): Promise<McTable> {
        return await apiGatewayService
            .invokeApi(API_PATH, "GET", null, {
                abortSignal: abortController.signal,
                apiType: ApiType.OLIVER,
            })
            .then((dto: McsResponseDto) => {
                return {
                    cursor: dto.cursor,
                    mcdata: dto.mcs.map(toMcsData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch Mcs data.");
            });
    }
}

export const hybridMcService = new HybridMcService();
