import { useTranslation } from "react-i18next";

import { ProductMappingItem } from "domain/licenses";
import { TemplateTableData } from "domain/workflowTemplates";

export enum BmdeOnboardingSteps {
    STEP_WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
    STEP_BOOTING_USB_DRIVE = "BOOTING_USB_DRIVE",
    STEP_INSTALLING_BMDE = "INSTALLING_BMDE",
    STEP_RUNNING_ERASURE = "RUNNING_ERASURE",
    STEP_VIEWING_REPORT = "VIEWING_REPORT",
}

export enum BdeOnboardingSteps {
    STEP_WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
    STEP_PRE_CONFIGURATION = "PRE_CONFIGURATION",
    STEP_BOOTING_USB_DRIVE = "BOOTING_USB_DRIVE",
    STEP_RUNNING_ERASURE = "RUNNING_ERASURE",
    STEP_VIEWING_REPORT = "VIEWING_REPORT",
}

export const BMDE_ONBOARDING_STEPS = Object.values(BmdeOnboardingSteps);

export const BDE_ONBOARDING_STEPS = Object.values(BdeOnboardingSteps);

export function isStepCompleted(focusedStepIndex: number, lastCompletedStepIndex: number): boolean {
    return focusedStepIndex <= lastCompletedStepIndex;
}

export function getValidLastCompletedStep(product: string, lastCompletedStep: string) {
    if (product == OnboardingProductType.BMDE) {
        return mapCompletedStepToBmdeOnboardingStep(lastCompletedStep);
    }
    return mapCompletedStepToBdeOnboardingStep(lastCompletedStep);
}

export function getLastCompletedStepIndexForBde(step: BdeOnboardingSteps | undefined) {
    if (!step) {
        return -1;
    }
    return BDE_ONBOARDING_STEPS.indexOf(step);
}

export function getLastCompletedStepIndexForBmde(step: BmdeOnboardingSteps | undefined) {
    if (!step) {
        return -1;
    }
    return BMDE_ONBOARDING_STEPS.indexOf(step);
}

export function getActiveStep(lastCompletedStepIndex: number): number {
    return lastCompletedStepIndex + 1;
}

export function isStepActive(lastCompletedStepIndex: number, currentStep: number): boolean {
    const activeStep = getActiveStep(lastCompletedStepIndex);
    return activeStep === currentStep;
}

export const mapCompletedStepToBmdeOnboardingStep = (lastCompletedStep: string) => {
    return BMDE_ONBOARDING_STEPS.find((step) => step === lastCompletedStep);
};

export const mapCompletedStepToBdeOnboardingStep = (lastCompletedStep: string) => {
    return BDE_ONBOARDING_STEPS.find((step) => step === lastCompletedStep);
};

export enum OnboardingProductType {
    BDE = "BDE",
    BMDE = "BMDE",
}

export type OnboardingProductTypeKey = keyof typeof OnboardingProductType;

export const OnboardingProductNames: Record<OnboardingProductType, string> = {
    [OnboardingProductType.BDE]: "Blancco Drive Eraser",
    [OnboardingProductType.BMDE]: "Mobile Diagnostics and Erasure",
};

export const SUB_STEPS_INSTALL_BMDE = [
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.prepareYourPC",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.plugInBootableUSB",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.turnOnOrReboot",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickPermanent",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickAutomaticPartitioning",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setAdminPassword",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.synchronize",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setupWifiConfiguration",
];

export function checkIfStepIsClickable(stepIndex: number, lastCompletedStepIndex: number) {
    return (
        stepIndex <= lastCompletedStepIndex ||
        stepIndex === getActiveStep(lastCompletedStepIndex) ||
        lastCompletedStepIndex === 4
    );
}

export function displayProgressBarProgress(lastCompletedStepIndex: number, previousProgress: number) {
    // TODO BCC-4404 Update logic for progress for the last step i.e "View report"
    if (lastCompletedStepIndex === -1) {
        return previousProgress;
    } else if (lastCompletedStepIndex === 0) {
        return previousProgress + 10;
    } else {
        return previousProgress + 20;
    }
}

export function checkIfMenuExpanded(lastCompletedStepIndex: number, currentStepIndex: number) {
    if (lastCompletedStepIndex === -1 || lastCompletedStepIndex === 4) {
        return false;
    }
    return (
        lastCompletedStepIndex !== -1 &&
        currentStepIndex > lastCompletedStepIndex &&
        isStepActive(lastCompletedStepIndex, currentStepIndex)
    );
}

export interface WorkflowTemplateData {
    name: string;
    description: string;
    templateUuid: string;
}

export function toWorkflowTemplateData(dto: TemplateTableData): WorkflowTemplateData {
    return {
        name: dto.name,
        description: dto.description,
        templateUuid: dto.uuid,
    };
}

export function getInitialProgressPercentage(stepIndex: number) {
    //TODO BCC-4404 Need to make the step indices dynamic for cases with and without Workflow Templates step
    if (stepIndex === 0) {
        return 20;
    } else if (stepIndex === 1) {
        return 40;
    } else if (stepIndex === 2) {
        return 60;
    } else if (stepIndex === 3) {
        return 80;
    } else if (stepIndex === 4) {
        return 100;
    } else {
        return 10;
    }
}

export function getHomepageProductsMappingList(): ProductMappingItem[] {
    const { t } = useTranslation();
    return [
        {
            productName: "Blancco Drive Eraser",
            licensesIds: ["1", "3", "5", "20", "50", "36"],
            description: t("Onboarding.home.products.blanccoDriveErasure"),
            onboarding: "BDE",
            group: "asset",
        },
        {
            productName: "Blancco Drive Eraser for Mac",
            licensesIds: [],
            description: t("Onboarding.home.products.blanccoDriveErasureForMac"),
            group: "asset",
        },
        {
            productName: "Blancco Drive Eraser for ARM",
            licensesIds: [],
            description: t("Onboarding.home.products.blanccoDriveErasureForArm"),
            group: "asset",
        },
        {
            productName: "Blancco Drive Verifier",
            licensesIds: ["37"],
            description: t("Onboarding.home.products.blanccoDriveVerifier"),
            group: "asset",
        },
        {
            productName: "Blancco Mobile Diagnostics and Erasure",
            licensesIds: ["2", "16", "17", "24", "30", "31", "32", "33", "34", "35", "46"],
            description: t("Onboarding.home.products.blanccoMobileDiagnosticsAndErasure"),
            onboarding: "BMDE",
            group: "asset",
        },
        {
            productName: "Blancco Eraser for Apple Devices",
            licensesIds: ["10"],
            description: t("Onboarding.home.products.blanccoEraserForAppleDevices"),
            group: "asset",
        },
        {
            productName: "Blancco Removable Media Eraser",
            licensesIds: ["11", "12"],
            description: t("Onboarding.home.products.blanccoRemovableMediaEraser"),
            group: "asset",
        },
        {
            productName: "Blancco WinEraser",
            licensesIds: [],
            description: t("Onboarding.home.products.blanccoWinEraser"),
            group: "asset",
        },
        {
            productName: "Blancco WinEraser for ARM",
            licensesIds: [],
            description: t("Onboarding.home.products.blanccoWinEraserForARM"),
            group: "asset",
        },
        {
            productName: "Blancco LUN Eraser",
            licensesIds: ["26", "38"],
            description: t("Onboarding.home.products.blanccoLunEraser"),
            group: "information",
        },
        {
            productName: "Blancco File Eraser",
            licensesIds: ["27", "28", "44"],
            description: t("Onboarding.home.products.blanccoFileEraser"),
            group: "information",
        },
        {
            productName: "Blancco Virtual Machine Eraser",
            licensesIds: ["39", "40"],
            description: t("Onboarding.home.products.blanccoVirtualMachineEraser"),
            group: "information",
        },
    ];
}
