import classNames from "classnames";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import style from "./mcs.scss";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const McsKebabMenu = (): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    return (
        <div className={classNames(style.tenantIcon)}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <li className={style.listItem}>
                        <MenuItemButton
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.MCS,
                                    action: Action.EDIT_MC,
                                });
                            }}
                            className={style.menuItem}
                        >
                            {t("Mcs.edit")}
                        </MenuItemButton>
                    </li>
                    <li className={style.listItem}>
                        <MenuItemButton
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.MCS,
                                    action: Action.UNREGISTER_MC,
                                });
                            }}
                            className={style.menuItem}
                        >
                            {t("Mcs.unregister")}
                        </MenuItemButton>
                    </li>
                    <li className={style.listItem}>
                        <MenuItemButton
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.MCS,
                                    action: Action.RESET_MC,
                                });
                            }}
                            className={style.menuItem}
                        >
                            {t("Mcs.reset")}
                        </MenuItemButton>
                    </li>
                </ul>
            </Menu>
        </div>
    );
};

export default connector(McsKebabMenu);
