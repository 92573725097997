import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "components/home/current-plan/current-plan.scss";
import PricingTierView from "components/tenants/PricingTierView";
import Heading from "components/typography/heading/Heading";
import { TenantDetails } from "store/tenantDetails";

export interface Props {
    tenantData: TenantDetails | undefined;
}

const CurrentPlan = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
    const capitalizeFirstOnly = (str: string | undefined): string =>
        str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : "";

    return (
        <>
            <div className={style.currentPlan}>
                <div>
                    <div className={style.planLabel}>{t("Onboarding.home.currentPlan").toUpperCase()}</div>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {`
                            ${capitalizeFirstOnly(props.tenantData?.type)}: 
                            ${capitalizeFirstOnly(props.tenantData?.tenantTier)}
                        `}
                    </Heading>
                </div>
                <div className={style.bundlesLabel}>
                    <button onClick={() => setPricingTierModalVisible(true)}>
                        <strong>View all Bundles</strong>
                    </button>
                </div>
            </div>
            {props.tenantData ? (
                <p>
                    <PricingTierView
                        tenantType={props.tenantData.type}
                        visibility={pricingTierModalVisible}
                        setVisibility={setPricingTierModalVisible}
                        selectedTier={props.tenantData.tenantTier}
                        tenantModal={props.tenantData.licensingModel}
                    />
                </p>
            ) : undefined}
        </>
    );
};

export default CurrentPlan;
