import * as React from "react";
import { connect } from "react-redux";

import ChooseWorkflowTemplate from "components/home/choose-workflow-template/ChooseWorkflowTemplate";
import InstallBmde from "components/home/install-bmde/InstallBmde";
import CreateBootableUsb from "components/home/onboarding-create-bootable-usb/CreateBootableUsb";
import RunErasure from "components/home/onboarding-run-erasure/RunErasure";
import ViewReport from "components/home/onboarding-view-report/ViewReport";
import { FEATURE_WORKFLOW_BMDE } from "components/licenses/common";
import { LicenseData } from "domain/licenses";
import {
    checkIfMenuExpanded,
    checkIfStepIsClickable,
    isStepCompleted,
    OnboardingProductType,
} from "domain/onboardings";
import { SoftwarePackagesData, Versions } from "domain/softwarePackages";
import { TemplateTableData } from "domain/workflowTemplates";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    lastCompletedStepIndex: number;
    setLastCompletedStepIndex: (value: number) => void;
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
    fetchedSoftwarePackageData?: SoftwarePackagesData;
    canViewReport: boolean;
    canViewWorkflows: boolean;
}

const BmdeSteps = (props: Props): JSX.Element => {
    const [showWorkflowStep, setShowWorkflowStep] = React.useState(false);

    React.useEffect(() => {
        props.fetchedLicenses.map((each) => each.type == FEATURE_WORKFLOW_BMDE && setShowWorkflowStep(true));
    }, []);

    return (
        <>
            {props.canViewWorkflows && showWorkflowStep && (
                <ChooseWorkflowTemplate
                    menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 0)}
                    stepCompleted={isStepCompleted(0, props.lastCompletedStepIndex)}
                    stepActive={true}
                    setLastCompletedStepIndex={props.setLastCompletedStepIndex}
                    defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                    requestFailureMessage={props.requestFailureMessage}
                />
            )}
            <CreateBootableUsb
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 1)}
                stepCompleted={isStepCompleted(1, props.lastCompletedStepIndex)}
                stepActive={false}
                setLastCompletedStepIndex={props.setLastCompletedStepIndex}
                licenses={props.licenses}
                fetchedSoftwarePackageData={props.fetchedSoftwarePackageData}
                stepClickable={checkIfStepIsClickable(1, props.lastCompletedStepIndex)}
            />
            <InstallBmde
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 2)}
                stepCompleted={isStepCompleted(2, props.lastCompletedStepIndex)}
                stepActive={false}
                stepClickable={checkIfStepIsClickable(2, props.lastCompletedStepIndex)}
            />
            <RunErasure
                menuExpanded={checkIfMenuExpanded(props.lastCompletedStepIndex, 3)}
                stepCompleted={isStepCompleted(3, props.lastCompletedStepIndex)}
                stepActive={false}
                stepClickable={checkIfStepIsClickable(3, props.lastCompletedStepIndex)}
            />
            {props.canViewReport && (
                <ViewReport
                    menuExpanded={true}
                    stepCompleted={isStepCompleted(4, props.lastCompletedStepIndex)}
                    stepActive={false}
                    stepClickable={checkIfStepIsClickable(4, props.lastCompletedStepIndex)}
                    product={OnboardingProductType.BMDE}
                />
            )}
        </>
    );
};

export default connector(BmdeSteps);
