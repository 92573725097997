import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";

import ArrowButton from "components/home/ArrowButton";
import style from "components/home/bde-home-page/getting-started.scss";
import WorkflowTemplateSubStep from "components/home/bde-home-page/WorkflowTemplateSubStep";
import { displayStepCompletionIcon } from "components/home/bmde-home-page/GettingStarted";
import { TemplateTableData } from "domain/workflowTemplates";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    setLastCompletedStepIndex: (value: number) => void;
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    setHideSecondStep?: (value: boolean) => void;
}

const ChooseWorkflowTemplate = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [showWorkflow, setShowWorkflow] = React.useState(false);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [workflowStepSuccess, setWorkflowStepSuccessState] = React.useState(false);
    const openWorkflowTemaplateView = () => {
        setShowWorkflow(!showWorkflow);
    };
    const setWorkflowStepSuccess = (newValue: boolean) => {
        if (newValue !== workflowStepSuccess) {
            props.setLastCompletedStepIndex(0);
        }
        setWorkflowStepSuccessState(newValue);
    };

    return (
        <>
            <div
                className={
                    showWorkflow
                        ? style.stepInProgress
                        : props.stepCompleted
                        ? style.successColor
                        : style.stepsToFollowLayout
                }
            >
                <div className={style.headerLayout} onClick={openWorkflowTemaplateView}>
                    <div className={style.step}>
                        <div className={style.stepWidthAlignment}>
                            {displayStepCompletionIcon(
                                showWorkflow,
                                showContent,
                                1,
                                props.stepActive,
                                theme.defaultStarColor,
                                theme.whiteColor,
                                theme.secondaryWarningBackgroundColor,
                                theme.descriptionTextColor
                            )}
                        </div>
                        <div className={style.stepHeading}>{t("Onboarding.gettingStartedFlow.workflowStep.title")}</div>
                        <ArrowButton success={showContent} showStep={showWorkflow} />
                    </div>
                </div>
                <div>
                    {showWorkflow ? (
                        props.requestFailureMessage ? (
                            <div>{props.requestFailureMessage}</div>
                        ) : (
                            <div>
                                <div className={style.divider} />
                                <WorkflowTemplateSubStep
                                    stepCompleted={props.stepCompleted}
                                    showWorflowTemplateView={setShowWorkflow}
                                    isStepCompleted={setWorkflowStepSuccess}
                                    showNextStep={setShowContent}
                                    defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                                    requestFailureMessage={props.requestFailureMessage}
                                    setHideSecondStep={props.setHideSecondStep}
                                />
                            </div>
                        )
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default connector(ChooseWorkflowTemplate);
